import {User, UserRole} from 'state/state'
import styled from 'styled-components'

export function getHighestRoleForUser(user: User): UserRole {
  const roles = user.userToSite.map(uts => uts.userRole)
  return roles.indexOf('CM') > -1
    ? 'CM'
    : roles.indexOf('BM') > -1
    ? 'BM'
    : roles.indexOf('GM') > -1
    ? 'GM'
    : roles.indexOf('LM') > -1
    ? 'LM'
    : roles.indexOf('SM') > -1
    ? 'SM'
    : 'BU'
}

export function role2level(level: UserRole) {
  switch (level) {
    case 'CM':
      return 'chains'
    case 'BM':
      return 'brands'
    case 'GM':
      return 'locationGroups'
    case 'LM':
      return 'locations'
    case 'SM':
      return 'sites'
    default:
      return 'sites'
  }
}

export function parseLevel(level: UserRole) {
  switch (level) {
    case 'CM':
      return 'chainId'
    case 'BM':
      return 'brandId'
    case 'GM':
      return 'locationGroupId'
    case 'LM':
      return 'locationId'
    case 'SM':
      return 'siteId'
    case 'BU':
      return 'siteId'
    default:
      return 'siteId'
  }
}

export interface AnyOrgLevel {
  name: string
  description?: string
  visible?: boolean
  locationGroups?: AnyOrgLevel[]
  locations?: AnyOrgLevel[]
  brands?: AnyOrgLevel[]
  sites?: AnyOrgLevel[]
  id: string
}

export const OffsetCheckboxList = styled.ul`
  margin-left: 1rem;
  list-style: none;
`

export const availableKeyValues = ['chains', 'locationGroups', 'locations', 'brands', 'sites']
