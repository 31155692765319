import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Helmet} from 'react-navi-helmet-async'
import {ErrorMessage} from '../../Components/Molecules/Errors/ErrorMessage'
import {Loader} from '../../Routes'
import {useAppState} from '../../state'
import {Text, Title, Tabs, TabPane, SelectOption} from '@fredman/chefstein-shared-frontend-components'
import styled from 'styled-components'
import {AlarmsFilters, AlarmsFilterView} from './filterView'
import {Alarm} from '../../state/alarms/state'
import {AlarmsTable} from './alarmsTable'
import {ResolveAlarmModal} from '../../modals/ResolveAlarmModal'
import {OrgLevelType} from '../../state/state'
import {useNavigation} from 'react-navi'
import {LoadMoreButton} from '../tasks/TodoTasks'
import {CenteredLayout} from '../common/centeredLayout'
import {checkPeriodValidity} from '../utils'

export const ALARM_PAGE_LIMIT = 10

export enum AlarmViewType {
  ALL = 'all',
  APPLIANCE_ALARMS = 'appliance',
  TASK_ALARMS = 'task'
}

const defaultStartDate = new Date(2021, 1, 1)
const defaultEndDate = new Date()
const defaultFilters = {
  alarmTypes: [],
  completedBy: [],
  startDate: defaultStartDate,
  endDate: defaultEndDate,
  defaultEndDate
}

export const AlarmsPage = () => {
  const {state, actions} = useAppState()
  const siteId = state.site!.id

  useEffect(() => {
    actions.v1.alarms.getAlarmsForSiteWithPagination({
      siteId,
      options: {
        limit: ALARM_PAGE_LIMIT,
        offset: 0,
        from: defaultFilters.startDate.toISOString(),
        to: defaultFilters.endDate.toISOString()
      },
      showLoadingIndicator: true
    })
  }, [actions.v1.tasks, siteId])
  const {loading, error} = state.v1.alarms

  if (loading) {
    return <Loader show={loading} />
  } else if (error) {
    return <ErrorMessage message={error.message} />
  } else {
    return <Content />
  }
}

const Content = () => {
  const {t} = useTranslation(['alarms', 'tasks'])
  const {state, actions} = useAppState()
  const [selectedPaneKey, setSelectedPaneKey] = useState<AlarmViewType>(AlarmViewType.ALL)
  const [alarmFilter, setAlarmFilter] = useState<AlarmsFilters>(defaultFilters)

  // The initial request gets the first batch, set the offset to second batch
  const [allAlarmsOffset, setAllAlarmsOffset] = useState(ALARM_PAGE_LIMIT)
  const [taskAlarmsOffset, setTaskAlarmsOffset] = useState(ALARM_PAGE_LIMIT)
  const [applianceAlarmsOffset, setApplianceAlarmsOffset] = useState(ALARM_PAGE_LIMIT)

  const [selectedAlarmId, setSelectedAlarmId] = useState<string | undefined>(undefined)

  const siteId = state.site!.id
  const locale = state.site!.locale || 'fi-FI'

  const navigation = useNavigation()
  useEffect(() => {
    // Redirect to home page if site is not selected because atm there is no alarms page for chain
    if (state.orgLevel?.type !== OrgLevelType.SITE) {
      navigation.navigate('/')
    }
  }, [state.orgLevel])

  const getShowingText = (selectedTab: AlarmViewType): string => {
    const countItems = (selectedTab: AlarmViewType): {part: number; total: number} => {
      switch (selectedTab) {
        case AlarmViewType.ALL:
          return {
            part: state.v1.alarms.allAlarms.length,
            total: state.v1.alarms.applianceAlarmsCount + state.v1.alarms.taskAlarmsCount
          }
        case AlarmViewType.APPLIANCE_ALARMS:
          return {
            part: state.v1.alarms.applianceAlarms.length,
            total: state.v1.alarms.applianceAlarmsCount
          }
        case AlarmViewType.TASK_ALARMS:
          return {
            part: state.v1.alarms.taskAlarms.length,
            total: state.v1.alarms.taskAlarmsCount
          }
        default:
          return {part: 0, total: 0}
      }
    }
    const {part, total} = countItems(selectedTab)

    return t('alarms:labels.showingItemsOutOfTotal', {part, total})
  }

  const applyFilters = (filters: AlarmsFilters, offset?: number, limit?: number) => {
    const {alarmTypes, completedBy, startDate, endDate} = filters
    actions.v1.alarms.getAlarmsForSiteWithPagination({
      siteId,
      options: {
        limit: limit || ALARM_PAGE_LIMIT,
        offset: offset || 0,
        ...(alarmTypes.length > 0 ? {alarmTypes: alarmTypes.map((taskTypes: SelectOption) => taskTypes.value)} : {}),
        ...(completedBy.length > 0 ? {completedByUsers: completedBy.map((users: SelectOption) => users.value)} : {}),
        ...(checkPeriodValidity(startDate, endDate) ? {from: startDate.toISOString(), to: endDate.toISOString()} : {})
      },
      showLoadingIndicator: false
    })
  }

  const resetFilters = (type: AlarmViewType) => {
    setAllAlarmsOffset(ALARM_PAGE_LIMIT)
    setTaskAlarmsOffset(ALARM_PAGE_LIMIT)
    setApplianceAlarmsOffset(ALARM_PAGE_LIMIT)

    setAlarmFilter(defaultFilters)
    applyFilters(defaultFilters)
  }

  const filtersComponent = () => (
    <AlarmsFilterView
      resetFilters={() => resetFilters(AlarmViewType.ALL)}
      selectedFilter={alarmFilter}
      setFilters={(key, value) => setAlarmFilter({...alarmFilter, [key]: value})}
      applyFilters={() => {
        applyFilters(alarmFilter)
      }}
      locale={locale}
    />
  )

  const fetchMoreData = (type: AlarmViewType) => {
    const updateAndReturnOffset = (type: AlarmViewType): number | null => {
      switch (type) {
        case AlarmViewType.ALL:
          setAllAlarmsOffset(allAlarmsOffset + ALARM_PAGE_LIMIT)
          return allAlarmsOffset
        case AlarmViewType.TASK_ALARMS:
          setTaskAlarmsOffset(taskAlarmsOffset + ALARM_PAGE_LIMIT)
          return taskAlarmsOffset
        case AlarmViewType.APPLIANCE_ALARMS:
          setApplianceAlarmsOffset(applianceAlarmsOffset + ALARM_PAGE_LIMIT)
          return applianceAlarmsOffset
        default:
          return null
      }
    }
    const newOffset = updateAndReturnOffset(type)
    if (newOffset === null) {
      return
    }

    const {alarmTypes, completedBy, startDate, endDate} = alarmFilter
    actions.v1.alarms.getMoreAlarmsForSite({
      siteId,
      type,
      options: {
        limit: ALARM_PAGE_LIMIT,
        offset: newOffset || 0,
        ...(alarmTypes.length > 0 ? {alarmTypes: alarmTypes.map((taskTypes: SelectOption) => taskTypes.value)} : {}),
        ...(completedBy.length > 0 ? {completedByUsers: completedBy.map((users: SelectOption) => users.value)} : {}),
        ...(checkPeriodValidity(startDate, endDate) ? {from: startDate.toISOString(), to: endDate.toISOString()} : {})
      }
    })
  }

  const loadMoreDataWrapper = (type: AlarmViewType) => {
    const hasMoreTasks = (type: AlarmViewType) => {
      switch (type) {
        case AlarmViewType.ALL:
          return (
            state.v1.alarms.allAlarms.length < state.v1.alarms.applianceAlarmsCount + state.v1.alarms.taskAlarmsCount
          )
        case AlarmViewType.TASK_ALARMS:
          return state.v1.alarms.taskAlarms.length < state.v1.alarms.taskAlarmsCount
        case AlarmViewType.APPLIANCE_ALARMS:
          return state.v1.alarms.applianceAlarms.length < state.v1.alarms.applianceAlarmsCount
        default:
          return false
      }
    }

    const loading = state.v1.alarms.loadingMoreData
    const showLoadMore = hasMoreTasks(type)
    if (!loading && showLoadMore) {
      return (
        <div style={{padding: '1.5rem 0 3rem 0'}}>
          <LoadMoreButton
            onClick={() => {
              fetchMoreData(type)
            }}
          />
        </div>
      )
    } else if (loading) {
      return (
        <div style={{padding: '1.5rem 0 3rem 0'}}>
          <Text size="S">{t('tasks:labels.loading', 'Loading...')}</Text>
        </div>
      )
    } else if (!showLoadMore) {
      return (
        <div style={{padding: '1.5rem 0 3rem 0'}}>
          <Text size="S">{t('tasks:labels.noMoreLoading', 'No more to load')}</Text>
        </div>
      )
    } else {
      return null
    }
  }

  const onAlarmResolveButtonClick = (alarmId?: string) => {
    const alarm = [
      ...state.v1.alarms.allAlarms,
      ...state.v1.alarms.applianceAlarms,
      ...state.v1.alarms.taskAlarms
    ].find(a => a.id === alarmId)
    if (!alarm) {
      console.error(`Invalid alarm id ${alarmId}, no alarm exists`)
      return
    }

    if (alarmId && alarm.isResolvable) {
      setSelectedAlarmId(alarmId)
    } else {
      navigation.navigate(`/appliances/${alarm.applianceId}`)
    }
  }

  const onCloseResolveAlarmModal = () => {
    setSelectedAlarmId(undefined)
  }

  const titleForModal = () => {
    const alarm = [
      ...state.v1.alarms.allAlarms,
      ...state.v1.alarms.applianceAlarms,
      ...state.v1.alarms.taskAlarms
    ].find(a => a.id === selectedAlarmId)
    if (alarm) {
      if (alarm.category === 'task') {
        return t('alarms:modal.title.taskAlarm', 'Task Alarm')
      } else {
        return t('alarms:modal.title.applianceAlarm', 'Appliance Alarm')
      }
    }
    return 'N/A'
  }

  return (
    <CenteredLayout>
      <Helmet title={t('common:routes.alarms', 'Alarms')} />
      <ResolveAlarmModal
        isOpen={selectedAlarmId !== undefined}
        onClose={onCloseResolveAlarmModal}
        onFormSubmit={async formData => {
          if (!selectedAlarmId || !state.site) {
            return
          }
          await actions.v1.alarms.resolveAlarm({
            alarmId: selectedAlarmId,
            resolution: formData.resolution,
            resolvedBy: formData.resolvedBy,
            siteId: state.site.id
          })
        }}
        alarmId={selectedAlarmId}
        title={titleForModal()}
        showApplianceLink
      />
      <Tabs
        defaultActiveKey={AlarmViewType.ALL}
        tabBarExtraContent={{
          left: (
            <div style={{width: '20%'}}>
              <Title level={3}>{t('alarms:labels.mainHeading', 'Alarm list')}</Title>
            </div>
          ),
          right: (
            <div style={{width: '30%'}}>
              <Text size="S">{getShowingText(selectedPaneKey)}</Text>
            </div>
          )
        }}
        onChangeKey={(key: string) => setSelectedPaneKey(key as AlarmViewType)}
      >
        <TabPane
          tab={<Text size="M">{t('alarms:labels.tabHeading.allAlarms', 'All alarms')}</Text>}
          tabKey={AlarmViewType.ALL}
        >
          <TableContent
            alarms={state.v1.alarms.allAlarms}
            locale={locale}
            filtersComponent={filtersComponent}
            onLoadMoreResolvedAlarms={() => loadMoreDataWrapper(AlarmViewType.ALL)}
            onResolveAlarm={onAlarmResolveButtonClick}
          />
        </TabPane>
        <TabPane
          tab={<Text size="M">{t('alarms:labels.tabHeading.applianceAlarms', 'Appliance alarms')}</Text>}
          tabKey={AlarmViewType.APPLIANCE_ALARMS}
        >
          <TableContent
            alarms={state.v1.alarms.applianceAlarms}
            locale={locale}
            filtersComponent={filtersComponent}
            onLoadMoreResolvedAlarms={() => loadMoreDataWrapper(AlarmViewType.APPLIANCE_ALARMS)}
            onResolveAlarm={onAlarmResolveButtonClick}
          />
        </TabPane>
        <TabPane
          tab={<Text size="M">{t('alarms:labels.tabHeading.taskAlarms', 'Task alarms')}</Text>}
          tabKey={AlarmViewType.TASK_ALARMS}
        >
          <TableContent
            alarms={state.v1.alarms.taskAlarms}
            locale={locale}
            filtersComponent={filtersComponent}
            onLoadMoreResolvedAlarms={() => loadMoreDataWrapper(AlarmViewType.TASK_ALARMS)}
            onResolveAlarm={onAlarmResolveButtonClick}
          />
        </TabPane>
      </Tabs>
    </CenteredLayout>
  )
}

interface TableContentProps {
  locale: string
  alarms: Alarm[]
  filtersComponent: any
  onLoadMoreResolvedAlarms: () => any
  onResolveAlarm: (alarmId: string) => any
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 20% auto;
  grid-template-rows: calc(100vh - 12rem);
  grid-template-areas: 'filters table';
`

const TableContent = (props: TableContentProps) => {
  return (
    <Grid>
      <aside style={{gridArea: 'filters', padding: '2rem 2rem 0 0'}}>
        <props.filtersComponent />
      </aside>
      <div style={{gridArea: 'table'}}>
        <AlarmsTable
          locale={props.locale}
          alarms={props.alarms}
          onLoadMoreResolvedAlarms={props.onLoadMoreResolvedAlarms}
          onResolveAlarm={props.onResolveAlarm}
        />
      </div>
    </Grid>
  )
}
