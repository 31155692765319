import React from 'react'
import _ from 'lodash'
import {useTranslation} from 'react-i18next'
import {
  Text,
  Table,
  ColumnDef,
  Tag,
  Select,
  Button,
  DatePicker,
  SelectOption,
  SharedIcon
} from '@fredman/chefstein-shared-frontend-components'
import {colors} from '../../sharedComponents/colors'

import {useAppState} from '../../state'
import {TaskAlarmDetails, CompletedTask} from '../../state/home/site/state'
import {CycleLog, UserLanguage} from '../../state/rest'
import {
  AutomaticCoolingExpandedRow,
  CoolingExpandedRow,
  ManualExpandedRow,
  TodoExpandedRow,
  WasteExpandedRow,
  HygieneExpandedRow,
  TemperatureExpandedRow,
  ErrorExpandedRow,
  WasteWithReasonExpandedRow
} from '../../Components/Views/Tasks/ExpandedRowContainers'
import {isDate} from 'date-fns'
import {getHumanizedDateWithTime, getHumanizedTimeString, parseStringToDate} from '../../Components/Atoms/Utils'
import styled from 'styled-components'
import {taskGroupIconPicker} from './utils'
import {translateTaskGroupName, translateTaskType} from '../../config/utils'
import {LoadMoreButton} from './TodoTasks'
import {AlarmSubType} from '../../state/alarms/state'
import {WasteTaskType} from '../../Components/Views/Tasks/CreateWasteTask'
import {AccessRights} from 'state/state'

interface CompletedTasksViewProps {
  locale: string
  loadMoreCompletedTasks: () => void
  deleteEvent: (taskId: string, eventId: string) => void
}

export const hasAlarmType = (alarms: TaskAlarmDetails[], alarmSubType: AlarmSubType) =>
  alarms.filter(alarm => alarm.alarmType === alarmSubType).length > 0

const canDeleteTask = (accessRights: AccessRights, site: string): boolean => {
  if (accessRights.superuser) return true

  const siteAccessRights = accessRights.write.sites.find(s => s === site)

  if (siteAccessRights) return true

  return false
}

export const CompletedTasksView = (props: CompletedTasksViewProps) => {
  const {state} = useAppState()
  const {t} = useTranslation('tasks')
  const isDeletingAllowed = canDeleteTask(state.me!.accessRights, state.site!.id)

  const siteTemperatureUnit = state.site!.temperatureUnit
  const siteWeightUnit = state.site!.weightUnit
  const {locale, loadMoreCompletedTasks, deleteEvent} = props

  const completedTasks = state.v1.tasks.completedTasks.data
    ? state.v1.tasks.completedTasks.data.tasks.map(task => {
        const handleDeleteEvent = (): void => {
          deleteEvent(task.id, task.eventId!)
        }
        const translateValues = (value: string | null): string | null => {
          if (value?.includes('unit')) {
            return value.replace('unit', t('tasks:labels.unitPlaceholder', 'unit'))
          } else if (value?.includes('customers')) {
            return value.replace('customers', t('tasks:labels.customerUnit', 'customers'))
          } else {
            return value
          }
        }

        const {coolingStart, coolingEnd, duration} = determineCoolingValues(
          locale,
          task.taskType.id,
          task.coolingValues,
          task.cycleLog
        )
        console.log('[TASK SCHEDULE INSTANCE]', task)
        return {
          ...task,
          taskGroupType: task.taskGroup.icon,
          taskGroupName: task.taskGroup.name,
          type: task.taskType.id,
          scheduleInstance: task.taskType.id === 'automaticCooling' ? task.cycleLog?.endedAt : task.scheduleInstance,
          measurementTarget: determineMeasurementTarget(task, task.taskType.id),
          measuredValue: translateValues(determineMeasuredValue(task, siteTemperatureUnit, siteWeightUnit)),
          taskCompletedTime:
            task.taskType.id === 'automaticCooling' ? coolingEnd : formatToTimestamp(task.scheduleInstance, locale),
          coolingStartedTime: coolingStart,
          coolingEndedTime: coolingEnd,
          coolingDuration: duration,
          reasonForWaste:
            task.wasteTaskType === WasteTaskType.WasteAmount && task.suggestions?.length > 0
              ? task.suggestions[0]
              : null,
          deleteHandler: handleDeleteEvent,
          measurementMethod: getMeasurementMethod(task)
        }
      })
    : []

  const columnDefs = [
    {
      title: undefined,
      dataKeys: ['taskGroup'],
      widthPercentage: '4rem',
      component: (props: any) => (
        <div style={{paddingLeft: '1rem'}}>
          <SharedIcon
            icon={taskGroupIconPicker(props.values[0].icon)}
            height="2em"
            width="2em"
            fill={colors.brand.cobalt}
          />
        </div>
      )
    },
    {
      title: t('tasks:labels.columnNameTaskGroup', 'Task group'),
      dataKeys: ['taskGroupName'],
      widthPercentage: '10%',
      component: (props: any) => (
        <Text size="S" strong={props.selected} textTransform="capitalize">
          {translateTaskGroupName(props.values[0])}
        </Text>
      )
    },
    {
      title: t('tasks:labels.columnNameTaskName', 'Name'),
      dataKeys: ['name'],
      widthPercentage: '35%',
      component: (props: any) => (
        <Text size="S" strong={props.selected} textTransform="capitalize">
          {props.values[0]}
        </Text>
      )
    },
    {
      title: t('tasks:labels.columnNameTaskType', 'Type'),
      dataKeys: ['type'],
      widthPercentage: '10%',
      component: (props: any) => (
        <Text size="S" strong={props.selected} textTransform="capitalize">
          {props.values ? translateTaskType(props.values[0]) : '-'}
        </Text>
      )
    },
    {
      title: t('tasks:labels.columnNameCompletedDate', 'Completed Date'),
      dataKeys: ['scheduleInstance'],
      widthPercentage: '12%',
      component: (props: any) => (
        <Text size="S" strong={props.selected}>
          {getHumanizedDateWithTime(parseStringToDate(props.values[0] || props.values[1]), locale)}
        </Text>
      ),
      sortFn: (data: any[], sortKey: string, order: 'ascending' | 'descending') => {
        const sortedData = _.sortBy(data, [sortKey])
        return order === 'ascending' ? sortedData : sortedData.reverse()
      }
    },
    {
      title: t('tasks:labels.columnNameUserAccount', 'User account'),
      dataKeys: ['actorName'],
      component: (props: any) => (
        <Text size="S" strong={props.selected}>
          {props.values[0] ? props.values[0] : '-'}
        </Text>
      )
    },
    {
      title: t('tasks:labels.columnNameMeasuredValue', 'Measured Value'),
      dataKeys: ['measuredValue', 'failedMeasurement'],
      widthPercentage: '15%',
      component: (props: any) => {
        if (props.values[0] === null) {
          return (
            <Text size="S" strong={props.selected}>
              N/A
            </Text>
          )
        } else if (props.values[1] === true) {
          return <Tag styleType="error" size="S">{`${props.values[0]}`}</Tag>
        } else {
          return <Tag styleType="success" size="S">{`${props.values[0]}`}</Tag>
        }
      },
      sortFn: (data: any[], sortKey: string, order: 'ascending' | 'descending') => {
        const sortedData = _.sortBy(data, [sortKey])
        return order === 'ascending' ? sortedData : sortedData.reverse()
      }
    }
  ] as ColumnDef[]

  const expandable = {
    lazyLoading: true,
    expandedRowRender: (item: any) => {
      switch (item.type) {
        case 'cooling':
          return <CoolingExpandedRow item={item} unit={siteTemperatureUnit} showDelete={isDeletingAllowed} />
        case 'automaticCooling':
          return <AutomaticCoolingExpandedRow item={item} unit={siteTemperatureUnit} />
        case 'manual':
          return <ManualExpandedRow item={item} showDelete={isDeletingAllowed} />
        case 'todo':
          return <TodoExpandedRow item={item} showDelete={isDeletingAllowed} />
        case 'singlechoice':
          return <TodoExpandedRow item={item} showDelete={isDeletingAllowed} />
        case 'hygiene':
          return <HygieneExpandedRow item={item} unit={siteWeightUnit} showDelete={isDeletingAllowed} />
        case 'waste':
          return item.wasteTaskType === WasteTaskType.WasteAmount ? (
            <WasteWithReasonExpandedRow item={item} unit={siteWeightUnit} showDelete={isDeletingAllowed} />
          ) : (
            <WasteExpandedRow item={item} unit={siteWeightUnit} showDelete={isDeletingAllowed} />
          )
        case 'temperature':
          return <TemperatureExpandedRow item={item} unit={siteTemperatureUnit} showDelete={isDeletingAllowed} />
        case 'equipmentTemperature':
          return <TemperatureExpandedRow item={item} unit={siteTemperatureUnit} showDelete={isDeletingAllowed} />
        default:
          return <ErrorExpandedRow />
      }
    }
  }
  return (
    <div style={{height: '100%', marginTop: '-1px'}}>
      <Table
        columnDefs={columnDefs}
        data={completedTasks}
        lastRow={RenderLastRow(state, loadMoreCompletedTasks)}
        expandable={expandable}
        rowHoverBackgroundColor={colors.system.white}
        rowExpandedBackgroundColor={colors.system.white}
      />
    </div>
  )
}

const RenderLastRow = (state: any, loadMoreData: any) => {
  const {t} = useTranslation('tasks')
  const {loadingMoreTasks, data} = state.v1.tasks.completedTasks
  const loading = loadingMoreTasks
  const hasMoreTasks = data.count > data.tasks.length
  if (!loading && hasMoreTasks) {
    return (
      <div style={{margin: '0.5rem 0'}}>
        <LoadMoreButton onClick={loadMoreData} />
      </div>
    )
  } else if (loading) {
    return (
      <div style={{margin: '1.5rem 0'}}>
        <Text size="S">{t('tasks:labels.loading', 'Loading...')}</Text>
      </div>
    )
  } else if (!hasMoreTasks) {
    return (
      <div style={{margin: '1.5rem 0'}}>
        <Text size="S">{t('tasks:labels.noMoreLoading', 'No more to load')}</Text>
      </div>
    )
  } else {
    return null
  }
}

export interface CompletedTaskFilter {
  taskGroups: SelectOption[]
  taskTypes: SelectOption[]
  userAccounts: SelectOption[]
  measurementValue: SelectOption[]
  startDate: Date
  endDate: Date
}

interface CompletedTaskFilterViewProps {
  locale: string
  selectedFilter: CompletedTaskFilter
  setFilters: (key: string, value: any) => void
  resetFilters: () => void
  applyFilters: () => void
}

const diffInMinutes = (start: Date, end: Date): number => {
  const diffInMilliseconds = Math.abs(end.valueOf() - start.valueOf())
  return Math.floor(diffInMilliseconds / 1000 / 60)
}

export const roundToTwoDecimal = (number: number): number => Math.round((number + Number.EPSILON) * 100) / 100

const formatToTimestamp = (date: string, locale: string): string | null => {
  const dateObj = new Date(date)
  return isDate(dateObj) ? getHumanizedTimeString(dateObj, locale) : null
}

const formatCoolingValues = (start: string | null, end: string | null, locale: string) => {
  const startDate = start ? new Date(start) : undefined
  const endDate = end ? new Date(end) : undefined
  const duration = isDate(startDate) && isDate(endDate) ? diffInMinutes(startDate as Date, endDate as Date) : undefined
  return typeof duration === 'number' && start && end
    ? {coolingStart: formatToTimestamp(start, locale), coolingEnd: formatToTimestamp(end, locale), duration}
    : {coolingStart: null, coolingEnd: null, duration: undefined}
}

const determineCoolingValues = (
  locale: string,
  taskType: string,
  coolingValues: {
    timestamp: string
    value: string
  }[],
  cycleLog: CycleLog | null
): {coolingStart: string | null; coolingEnd: string | null; duration: number | undefined} => {
  switch (taskType) {
    case 'automaticCooling':
      const startCycle = cycleLog ? cycleLog.startedAt : null
      const endCycle = cycleLog ? cycleLog.endedAt : null
      return formatCoolingValues(startCycle, endCycle, locale)
    case 'cooling':
      let coolingObj
      if (coolingValues[0] && coolingValues[1]) {
        coolingObj =
          coolingValues[0].timestamp > coolingValues[1].timestamp
            ? {startCooling: coolingValues[1].timestamp, endCooling: coolingValues[0].timestamp}
            : {startCooling: coolingValues[0].timestamp, endCooling: coolingValues[1].timestamp}
      } else {
        coolingObj = {startCooling: null, endCooling: null}
      }
      return formatCoolingValues(coolingObj.startCooling, coolingObj.endCooling, locale)
    default:
      return {coolingStart: null, coolingEnd: null, duration: undefined}
  }
}

const compareCoolingValues = (
  firstInArray: {timestamp: string; value: string},
  lastInArray: {timestamp: string; value: string}
) => {
  const compareCoolingValuesTimestamps = (first: {value: number; date: Date}, last: {value: number; date: Date}) => {
    return first.date > last.date
      ? {firstValue: last.value, lastValue: first.value}
      : {firstValue: first.value, lastValue: last.value}
  }
  const firstArrayDate = new Date(firstInArray.timestamp)
  const lastArrayDate = new Date(lastInArray.timestamp)
  return typeof firstInArray.value === 'number' &&
    typeof lastInArray.value === 'number' &&
    isDate(firstArrayDate) &&
    isDate(lastArrayDate)
    ? compareCoolingValuesTimestamps(
        {value: firstInArray.value, date: firstArrayDate},
        {value: lastInArray.value, date: lastArrayDate}
      )
    : {firstValue: undefined, lastValue: undefined}
}

const getAutomaticCoolingMeasuredValues = (task: CompletedTask): {start: string; end: string} => {
  const limitStart = task.cycleLog?.startLimit ? roundToTwoDecimal(task.cycleLog?.startLimit) : ''
  const targetEnd = task.cycleLog?.targetLimit ? roundToTwoDecimal(task.cycleLog?.targetLimit) : ''
  const peakTemperature = task.cycleLog?.firstMeasurement ? roundToTwoDecimal(task.cycleLog?.firstMeasurement) : ''
  const lastMeasurement = task.cycleLog?.lastMeasurement ? roundToTwoDecimal(task.cycleLog?.lastMeasurement) : ''
  const alarms = task.alarms
  if (alarms.length > 0) {
    const hasLowAlarm = hasAlarmType(alarms, AlarmSubType.Low)
    const hasCoolingTimeAlarm = hasAlarmType(alarms, AlarmSubType.CoolingTime)
    const start = hasLowAlarm ? peakTemperature : limitStart
    const end = hasCoolingTimeAlarm ? lastMeasurement : targetEnd
    return {start: String(start), end: String(end)}
  } else {
    return {start: String(limitStart), end: String(targetEnd)}
  }
}

const determineMeasuredValue = (task: CompletedTask, temperatureUnit: string, weightUnit: string): string | null => {
  if (task.taskType.id === 'automaticCooling') {
    const {start, end} = getAutomaticCoolingMeasuredValues(task)
    return `${start} -> ${end}°${temperatureUnit}`
  } else if (task.taskType.id === 'cooling') {
    const {firstValue, lastValue} = compareCoolingValues(task.coolingValues[0], task.coolingValues[1])
    return firstValue !== undefined && lastValue !== undefined
      ? `${firstValue} -> ${lastValue}°${temperatureUnit}`
      : null
  } else if (['temperature', 'equipmentTemperature'].includes(task.taskType.id)) {
    return typeof task.value === 'number' ? `${task.value}°${temperatureUnit}` : null
  } else if (task.taskType.id === 'waste' && task.wasteTaskType !== 'CustomerAmount') {
    return typeof task.value === 'number' ? `${task.value} ${task.unit || weightUnit || 'unit'}` : null
  } else if (task.taskType.id === 'waste' && task.wasteTaskType === 'CustomerAmount') {
    return typeof task.value === 'number' ? `${task.value} ${task.unit || 'customers'}` : null
  } else {
    return typeof task.value === 'number' ? `${task.value} ${task.unit ? task.unit : 'unit'}` : null
  }
}

//TODO revisit this logic when task view models are created. Ideally user defines the measurement target always when they complete the task
const determineMeasurementTarget = (task: CompletedTask, type: string) => {
  if (['hygiene', 'temperature', 'cooling', 'automaticCooling', 'manual'].includes(type)) {
    return task.suggestions?.[0] || '-'
  } else if (type === 'equipmentTemperature') {
    return task.equipmentName
  } else if (type === 'waste' && task.wasteTaskType === 'WasteAmount') {
    return task.ingredients ? task.ingredients[0] : null
  } else {
    return null
  }
}

const getMeasurementMethod = (task: CompletedTask) => {
  if (task.taskType.id === 'cooling') {
    return task.coolingValues?.map(value => value.measurementMethod) || []
  } else if (['hygiene', 'temperature', 'equipmentTemperature'].includes(task.taskType.id)) {
    return [task.measurementMethod]
  }
  return null
}

export const CompletedTaskFilterView = (props: CompletedTaskFilterViewProps) => {
  const {state} = useAppState()
  const {t} = useTranslation('tasks')
  const {filters} = state.v1.tasks
  const {resetFilters, applyFilters, setFilters, selectedFilter} = props

  // any IETF language tags: en, fi, etc.
  const localeForDatePicker = state.me?.user.language || UserLanguage.en
  return (
    <>
      <InputField data-cy="filter-completedDate">
        <label htmlFor="completedDate">
          <Text size="S">{t('tasks:labels.columnNameCompletedDate', 'Completed Date')}</Text>
        </label>
        <DatePicker
          id="completedDate"
          onChange={setFilters}
          startDate={selectedFilter.startDate}
          endDate={selectedFilter.endDate}
          locale={localeForDatePicker}
        />
      </InputField>

      <InputField data-cy="filter-taskGroups">
        <label htmlFor="taskGroups">
          <Text size="S">{t('tasks:labels.columnNameTaskGroup', 'Task group')}</Text>
        </label>
        <Select
          id="taskGroups"
          onChange={value => setFilters('taskGroups', value)}
          value={selectedFilter.taskGroups}
          placeholder={t('tasks:labels.selectFilters', 'Select...')}
          options={filters.taskGroups.map(value => ({
            value: value.id,
            label: translateTaskGroupName(value.name)
          }))}
          multiple
        />
      </InputField>
      <InputField data-cy="filter-taskTypes">
        <label htmlFor="taskTypes">
          <Text size="S">{t('tasks:labels.columnNameTaskType', 'Type')}</Text>
        </label>
        <Select
          id="taskTypes"
          onChange={value => setFilters('taskTypes', value)}
          value={selectedFilter.taskTypes}
          placeholder={t('tasks:labels.selectFilters', 'Select...')}
          options={filters.taskTypes.map(value => ({value: value, label: translateTaskType(value)}))}
          multiple
        />
      </InputField>

      <InputField data-cy="filter-userAccounts">
        <label htmlFor="userAccounts">
          <Text size="S">{t('tasks:labels.columnNameUserAccount', 'User account')}</Text>
        </label>
        <Select
          id="userAccounts"
          onChange={value => setFilters('userAccounts', value)}
          value={selectedFilter.userAccounts}
          placeholder={t('tasks:labels.selectFilters', 'Select...')}
          options={filters.userAccounts.map(value => ({value: value.id, label: value.name}))}
          multiple
        />
      </InputField>
      <InputField data-cy="filter-MeasurementValue">
        <label htmlFor="MeasurementValue">
          <Text size="S">{t('tasks:labels.measurementValue', 'Measurement Value')}</Text>
        </label>
        <Select
          id="MeasurementValue"
          onChange={value => setFilters('measurementValue', value)}
          value={selectedFilter.measurementValue}
          placeholder={t('tasks:labels.selectFilters', 'Select...')}
          options={[
            {value: 'succeededMeasurements', label: t('tasks:labels.passedValue', 'Passed')},
            {value: 'failedMeasurements', label: t('tasks:labels.failedValue', 'Failed')}
          ]}
          multiple
        />
      </InputField>
      <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: '0.5rem'}}>
        <Button buttonStyle="secondary" buttonSize="small" onClick={resetFilters} data-cy="button-reset">
          {t('tasks:buttons.resetFilters', 'Reset')}
        </Button>
        <Button buttonStyle="primary" buttonSize="small" onClick={applyFilters} data-cy="button-apply">
          {t('tasks:buttons.applyFilters', 'Apply filters')}
        </Button>
      </div>
    </>
  )
}

export const InputField = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  gap: 0.25rem;
`
