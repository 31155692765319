import {SensorValue} from '../rest'
import {User} from '../state'

export enum AlarmType {
  Temperature = 'TEMPERATURE',
  Humidity = 'HUMIDITY',
  Cooling = 'COOLING',
  Disconnect = 'DISCONNECT',
  Calibration = 'CALIBRATION',
  Battery = 'BATTERY',
  Waste = 'WASTE',
  Hygiene = 'HYGIENE',
  Manual = 'MANUAL',
  SensorMalfunction = 'SENSOR' // Not used
}

export enum AlarmSubType {
  High = 'HIGH',
  Low = 'LOW',
  CoolingTime = 'COOLINGTIME'
}

export interface Alarm {
  category: 'task' | 'equipment'
  id: string
  status: string
  name: string
  triggeredTime: string
  resolvedTime: string
  measuredValue: number
  reason: string
  type: AlarmType
  subtype: AlarmSubType
  completedBy: string
  duration: number
  note: string | null
  measurementTarget: string | null
  targetDay: string // for calibration expired
  limits: {
    minValue: number
    maxValue: number
  } | null
  sensorRole: string | null
  unit: string | null
  endedAtTime: string | null
  isResolvable: boolean
  applianceId: string | null
  applianceType: string | null
  taskId: string | null
  latestValue: SensorValue | null
  resolvedBy: string
  actor: User | null
}

export type CycleLogAlarm = {
  id: string
  createdAt: string
  startedAt: string
  endedAt?: string
  firstMeasurement: number
  lastMeasurement: number
  startLimit: number
  targetLimit: number
  alarmThreshold: number
  alarmValue: number
  alarmType: AlarmSubType
  reason?: string
  resolution?: string
  resolvedAt?: string
  resolvedBy?: string
  type?: string
}

export interface AlarmsState {
  filters: {
    alarmTypes: string[] // {id: string; name: string}[]
    completedBy: {id: string; name: string}[]
  }
  allAlarms: Alarm[]
  applianceAlarms: Alarm[]
  taskAlarmsCount: number
  applianceAlarmsCount: number
  taskAlarms: Alarm[]
  loading: boolean
  loadingMoreData: boolean
  error: Error | null
}

export const state: AlarmsState = {
  filters: {
    alarmTypes: [],
    completedBy: []
  },
  allAlarms: [],
  applianceAlarms: [],
  taskAlarms: [],
  taskAlarmsCount: 0,
  applianceAlarmsCount: 0,
  loading: false,
  loadingMoreData: false,
  error: null
}
