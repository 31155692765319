import React, {useEffect, useRef, useState} from 'react'
import {
  createEmbeddingContext,
  EmbeddingContext,
  DashboardExperience,
  ExperienceFrameMetadata,
  ChangeEvent,
  EventMessageValues,
  ChangeEventName,
  EmbeddingEvents
} from 'amazon-quicksight-embedding-sdk'
import {useAppState} from '../../state'
import {Loader} from '../../Routes'
import {useTranslation} from 'react-i18next'
import {ErrorPage} from '../../Components/Views/Errors/ErrorPages'
import {addFilterChainName, getLanguageToLocale, transformLanguageObjToParameterObj} from './Utils'
import styled from 'styled-components'

const getLanguageParameters = (translations: object) => {
  const parameters = transformLanguageObjToParameterObj(translations)
  return parameters
}

const QuicksightDiv = styled.div`
  width: 100%;
  padding: 0 6%;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: #f2ede5;
`

interface QuickSightViewProps {
  isTestDashboard: boolean
}

const QuickSightView = ({isTestDashboard}: QuickSightViewProps) => {
  const {state, actions} = useAppState()
  const {t} = useTranslation(['dashboard'])

  const {chains, selectedChainId} = state
  const selectedChainName = chains.find(chain => chain.id === selectedChainId)?.name

  const dashboardRef = useRef<any>(null)
  const [dashboardId, setDashboardId] = useState('')
  const [embeddedDashboard, setEmbeddedDashboard] = useState<DashboardExperience | null>(null)
  const [dashboardUrl, setDashboardUrl] = useState<string | null>(null)
  const [embeddingContext, setEmbeddingContext] = useState<EmbeddingContext | null>(null)
  const [showErrorPage, setShowErrorPage] = useState(false)
  const [language, setLanguage] = useState(state.me?.user.language)

  const handleFrameErrors = (event: EmbeddingEvents, metadata?: ExperienceFrameMetadata) => {
    const changeEvent = event as ChangeEvent<ChangeEventName, EventMessageValues>
    if (changeEvent.eventLevel === 'ERROR') {
      console.error(
        'Embedding experience for Frame failed with error message:',
        changeEvent.message,
        ' for following data ',
        changeEvent.data
      )
      setShowErrorPage(true)
      return
    }
    if (changeEvent.eventLevel === 'WARN') {
      console.warn(
        'Embedding experience has following warning:',
        changeEvent.message,
        ' for following data ',
        changeEvent.data
      )
    }
  }

  const handleContentErrors = (event: EmbeddingEvents, metadata?: ExperienceFrameMetadata) => {
    if (event.eventName === 'ERROR_OCCURRED') {
      console.error(
        'Embedding experience for Content failed with error message:',
        event.message,
        ' for following data ',
        event.data
      )
      setShowErrorPage(true)
    }
  }

  // Set HTML level language
  useEffect(() => {
    const translations = t('dashboard:labels', {returnObjects: true})
    setLanguage(state.me?.user.language)
    const parameters = getLanguageParameters(translations)
    if (embeddedDashboard) embeddedDashboard?.setParameters(parameters)
  }, [state.me?.user.language])

  // Get dashboard URL
  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = await actions.getEmbeddedUrl(isTestDashboard)
        setDashboardUrl(url)
        if (url === '') {
          setShowErrorPage(true)
        }
      } catch (error) {
        setDashboardUrl(null)
      }
    }

    const timeout = setTimeout(fetchData, 10)

    return () => clearTimeout(timeout)
  }, [])

  const createContext = async () => {
    const context = await createEmbeddingContext()
    setEmbeddingContext(context)
  }

  // Create embedding context
  useEffect(() => {
    if (dashboardUrl) {
      createContext()
    }
  }, [dashboardUrl])

  // Create Dashboard Experience and embed
  useEffect(() => {
    if (embeddingContext) {
      embed()
    }
  }, [embeddingContext])

  const embed = async () => {
    const locale = getLanguageToLocale(language)
    const translations = t('dashboard:labels', {returnObjects: true})
    let parameters = getLanguageParameters(translations)
    parameters = addFilterChainName(parameters, selectedChainName!)
    const embeddingContext = await createEmbeddingContext()

    const frameOptions = {
      url: dashboardUrl || '',
      container: dashboardRef.current,
      onChange: handleFrameErrors,
      withIframePlaceholder: true
    }

    const contentOptions = {
      toolbarOptions: {
        reset: true
      },
      locale: locale,
      parameters: parameters,
      onMessage: handleContentErrors
    }
    const newEmbeddedDashboard = await embeddingContext!.embedDashboard(frameOptions, contentOptions)
    setEmbeddedDashboard(newEmbeddedDashboard)
  }

  // Navigate to dashboard (In case we have more than one dashboard in the future)
  useEffect(() => {
    if (embeddedDashboard) {
      embeddedDashboard.navigateToDashboard(dashboardId, {})
    }
  }, [dashboardId])

  return (
    <QuicksightDiv>
      {dashboardUrl ? (
        <QuicksightDiv ref={dashboardRef}></QuicksightDiv>
      ) : showErrorPage ? (
        <ErrorPage
          heading="Dashboard Error"
          paragraph={t('msg:newsTickerError', 'If the problem persists, please contact customer support.')}
        />
      ) : (
        <Loader show={true} />
      )}
    </QuicksightDiv>
  )
}

export default QuickSightView
