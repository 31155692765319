import React from 'react'
import styled from 'styled-components'

import {FullScreenMainLayout} from '../../Layout/Layout'
import {useAppState} from '../../../state'
import {Helmet} from 'react-navi-helmet-async'
import {useTranslation} from 'react-i18next'
import QuickSightView from '../../Atoms/QuicksightView'

const DashboardContainer = styled.div`
  height: 100vh;
  width: 100%;
`
//Dashboard component that the production uses in the /dashboard url
const DashboardView = () => {
  const {t} = useTranslation()
  return (
    <FullScreenMainLayout variant="white">
      <Helmet title={t('common:routes.dashboard', 'Dashboard')} />
      <DashboardContainer>
        <QuickSightView isTestDashboard={false} />
      </DashboardContainer>
    </FullScreenMainLayout>
  )
}

export default () => {
  const {state} = useAppState()
  return !!state.me && !!state.currentUser ? <DashboardView /> : null
}
