import React, {useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import {
  FilterCard,
  Button,
  Modal,
  ModalProps,
  Icon,
  Text,
  SharedIcon
} from '@fredman/chefstein-shared-frontend-components'
import {colors} from '../../sharedComponents/colors'

import _ from 'lodash'
import i18n from 'i18next'

import {useAppState} from '../../state'
import {PerformableTask} from '../../state/performTask/state'
import {Loader} from '../../Routes'
import {UnscheduledTaskGroup} from '../../state/unscheduledTasks/state'
import {translateTaskGroupName} from '../../config/utils'
import {UmbrellaCategory} from '../../state/home/site/state'
import {taskGroupIconPicker} from '../tasks/utils'

export const translateUmbrellaTypes = (key: UmbrellaCategory) =>
  getDefaultUmbrellaCategory().find(u => key === u.key)?.name

export const getTaskUmbrellaColor = (key: UmbrellaCategory) =>
  getDefaultUmbrellaCategory().find(u => key === u.key)?.color
export const getDefaultUmbrellaCategory = () => [
  {
    key: UmbrellaCategory.COOKING,
    name: i18n.t('home:task.umbrellaCategory.cooking', 'Cooking tasks'),
    color: colors.brand.lavendel
  },
  {
    key: UmbrellaCategory.CLEANING,
    name: i18n.t('home:task.umbrellaCategory.cleaning', 'Cleaning tasks'),
    color: colors.brand.candyPink
  },
  {
    key: UmbrellaCategory.WASTE,
    name: i18n.t('home:task.umbrellaCategory.waste', 'Waste tasks'),
    color: colors.brand.tabrizBlue
  },
  {
    key: UmbrellaCategory.OTHER,
    name: i18n.t('home:task.umbrellaCategory.other', 'Other tasks'),
    color: colors.brand.citrone
  }
]

enum ModalState {
  ShowTaskGroups,
  ShowTasksForGroup
}

interface UnscheduledTaskModalProps extends ModalProps {
  onPerformTask?: (data: PerformableTask) => void
}
export const UnscheduledTaskModal: React.FC<UnscheduledTaskModalProps> = ({
  isOpen,
  onClose,
  onPerformTask,
  ...rest
}) => {
  const {t} = useTranslation()
  const [stage, setStage] = useState<ModalState>(ModalState.ShowTaskGroups)
  const [selectedTaskGroup, setTaskGroup] = useState<UnscheduledTaskGroup | null>(null)

  const {state, actions} = useAppState()
  const {pending, data} = state.v1.unscheduledTasks
  const unscheduledTaskGroups = _.groupBy(data, 'umbrellaCategory')
  useEffect(() => {
    setStage(ModalState.ShowTaskGroups)
  }, [isOpen])

  useEffect(() => {
    actions.v1.unscheduledTasks.getUnscheduledTasks({siteId: state.site!.id})
  }, [state.site, actions.v1.unscheduledTasks])

  const handleTaskGroupClick = (taskGroup: UnscheduledTaskGroup) => {
    setStage(ModalState.ShowTasksForGroup)
    setTaskGroup(taskGroup)
  }

  const handleGoBack = () => {
    setStage(ModalState.ShowTaskGroups)
    setTaskGroup(null)
  }

  const handleTaskClick = async (taskId: string) => {
    onClose?.()
    const data = await actions.v1.performTask.getTask({
      siteId: state.site!.id,
      taskId
    })
    if (data) {
      !!onPerformTask && onPerformTask(data)
    }
  }

  const renderModalContent = () => {
    if (pending) {
      return <Loader show={pending} />
    } else {
      if (stage === ModalState.ShowTaskGroups) {
        return (
          <TaskUmbrellaContainer>
            {getDefaultUmbrellaCategory().map(umbrella => (
              <TaskGroupsContainer key={umbrella.key}>
                <div>{umbrella.name}</div>
                <TaskGroupsCards>
                  {unscheduledTaskGroups[umbrella.key] &&
                    unscheduledTaskGroups[umbrella.key].map((taskGroup, i) => (
                      <FilterCard
                        key={`${umbrella.key}-${i}`}
                        title={translateTaskGroupName(taskGroup.name)}
                        themeColor={umbrella.color}
                        icon={
                          <SharedIcon
                            icon={taskGroupIconPicker(taskGroup?.icon)}
                            height="2em"
                            width="2em"
                            fill={colors.brand.cobalt}
                          />
                        }
                        onClick={() => handleTaskGroupClick(taskGroup)}
                        data-cy="unschedule-task-group-button"
                      />
                    ))}
                </TaskGroupsCards>
              </TaskGroupsContainer>
            ))}
          </TaskUmbrellaContainer>
        )
      } else if (stage === ModalState.ShowTasksForGroup && selectedTaskGroup) {
        return (
          <TasksContainer>
            {selectedTaskGroup &&
              selectedTaskGroup.tasks.map((task, index) => (
                <div key={task.id}>
                  <TaskItem onClick={() => handleTaskClick(task.id)}>
                    <Text size="M">{task.name}</Text>
                  </TaskItem>
                  {index !== state.v1.generalTasks.data.tasks.length - 1 && <Divider />}
                </div>
              ))}
          </TasksContainer>
        )
      }
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        stage === 0
          ? t('home:task.modal.taskGroup', 'Choose a task group')
          : translateTaskGroupName(selectedTaskGroup!.name)
      }
      height="90%"
      maxWidth="1000px"
      headerLeftItem={
        stage === 0 ? (
          <Button
            buttonStyle="secondary"
            buttonSize="small"
            data-cy="perform-unscheduled-task-cancel"
            onClick={onClose}
          >
            {t('common:buttons.cancel', 'Cancel')}
          </Button>
        ) : (
          <Button
            buttonStyle="secondary"
            buttonSize="small"
            data-cy="perform-unscheduled-task-go-back"
            style={{padding: 0, border: 0, display: 'flex', alignItems: 'center'}}
            onClick={() => handleGoBack()}
          >
            <Icon type="arrowhead-left-small" />
            <Text size="M">{t('common:buttons.goback.allGroups', 'All groups')}</Text>
          </Button>
        )
      }
      headerRightItem={
        stage === 1 && (
          <Button
            buttonStyle="secondary"
            buttonSize="small"
            data-cy="perform-unscheduled-task-cancel"
            data-testid="cancel-button"
            onClick={onClose}
          >
            {t('common:buttons.cancel', 'Cancel')}
          </Button>
        )
      }
      headerBackgroundColor={selectedTaskGroup ? getTaskUmbrellaColor(selectedTaskGroup?.umbrellaCategory) : undefined}
    >
      {renderModalContent()}
    </Modal>
  )
}

const TasksContainer = styled.div`
  height: 100%;
  overflow: auto;
  padding: 1rem;
`

const TaskItem = styled.div`
  :hover {
    cursor: pointer;
    background-color: ${colors.system.lightGrey_5};
  }
  background-clip: padding-box;
  padding: 0.5rem 0.75rem;
`

const Divider = styled.hr`
  width: 100%;
  height: 1px;
  background-color: ${colors.system.grey_5};
  border: none;
  margin: 0;
`

const TaskUmbrellaContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  overflow: auto;
  height: 90%;
  padding: 1.5rem;
  gap: 1rem;
`

const TaskGroupsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const TaskGroupsCards = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
