import React from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

import {
  Button,
  Modal,
  ModalProps,
  SharedIcon,
  IconPalette,
  Title,
  Text
} from '@fredman/chefstein-shared-frontend-components'
import {colors} from '../../sharedComponents/colors'

interface DeleteSensorModalProps extends ModalProps {
  handleApprove: (index: number) => void
  sensor: number
}
const DeleteSensorModal: React.FC<DeleteSensorModalProps> = ({handleApprove, sensor, isOpen, onClose, ...rest}) => {
  const {t} = useTranslation()
  const approveSensorDelete = () => handleApprove(sensor)
  const detailsTitle = t('locations:sensor.remove.prompt', 'Before you proceed')
  const prompt = t(
    'locations:sensor.remove.alert',
    `You are about to delete a sensor from this site. All sensor related data, such as alarms will be deleted in the process.`
  )
  const instructions = t('locations:sensor.remove.instructions', 'Click continue and save to remove the sensor.')

  return (
    <StyledModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('locations:sensor.remove.title', 'Deleting sensor')}
      maxWidth="578px"
      headerLeftItem={
        <Button buttonStyle="secondary" buttonSize="small" data-cy="sensor-delete-cancel" onClick={onClose}>
          {t('common:buttons.cancel', 'Cancel')}
        </Button>
      }
      headerRightItem={
        <Button buttonStyle="primary" buttonSize="small" data-cy="delete-sensor-continue" onClick={approveSensorDelete}>
          {t('common:buttons.continue', 'Continue')}
        </Button>
      }
      headerBackgroundColor={colors.system.red}
    >
      <InstructionArea>
        <InstructionDetails title={detailsTitle} prompt={prompt} instructions={instructions} />
      </InstructionArea>
    </StyledModal>
  )
}

interface InstuctionDetailsProps {
  title: string
  prompt: string
  instructions: string
}
const InstructionDetails: React.FC<InstuctionDetailsProps> = ({title, prompt, instructions}) => {
  return (
    <InstructionDetailsContainer>
      <SharedIcon icon={IconPalette.AlertTriangle} width="3em" height="3em" fill={colors.brand.cobalt} />
      <div>
        <Title level={5} strong style={{paddingTop: '0.5rem', paddingBottom: '0.5rem'}}>
          {title}
        </Title>
        <Text size="S" style={{display: 'block', paddingBottom: '0.5rem'}}>
          {prompt}
        </Text>
        <Text size="S" strong>
          {instructions}
        </Text>
      </div>
    </InstructionDetailsContainer>
  )
}

const StyledModal = styled(Modal)`
  h2 {
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: ${colors.system.white};
  }
`

const InstructionArea = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;
  background-color: ${colors.system.lightGrey_5};
  border-radius: 1rem;
  height: 100%;
  overflow: auto;
  padding: 1.5rem;
`

const InstructionDetailsContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 1rem;
  padding-bottom: 1rem;
`

export default DeleteSensorModal
