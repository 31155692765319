import React from 'react'
import styled from 'styled-components'
import tiny from 'tinycolor2'

import {colors} from '../../../colors'
import {IconText} from '../IconText'
import {Icon} from '../../atoms/Icon'
import {Text} from '../../atoms/Text'
import {Avatar} from '../../atoms/Avatar'
import {useTranslation} from 'react-i18next'
import {useAppState} from '../../../../state'
import {UserRole} from '../../../../state/state'
import {Link} from 'react-navi'
import {IconLink} from '../../../../Assets/Icons/TinyIcons'
import {getHighestRoleForUser} from '../../../../Components/Views/Utils'

const supportInfo = {
  phone: '+358 10 3022400',
  email: 'servicedesk.cgiaromi.fi@cgi.com'
}

export interface UserOption {
  icon: string
  description: string
  onClick: () => void
  isExternalLink?: boolean
}

export interface UserSelectorProps {
  email: string
  firstName?: string
  lastName?: string
  photoUrl?: string
  userOptions: UserOption[]
}

const UserSelectorModal = styled.div`
  min-width: 245px;
  color: ${colors.brand.cobalt};
  padding: 1rem;
`

const Divider = styled.hr`
  margin: 1rem 0 0.5rem 0;
  border: transparent;
  border-bottom: 1px solid
    ${tiny(colors.system.lightGrey_5)
      .darken(15)
      .toHexString()};
`

const LargeUserInfoContainer = styled.div`
  cursor: pointer;
  display: grid;
  grid-template-columns: 2.6rem minmax(auto, auto);
  grid-gap: 1.25rem;
  align-items: center;
  justify-items: start;

  > :last-child {
    width: 100%;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`

const SelectItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const getUserName = (firstName: string | undefined, lastName: string | undefined, email: string) => {
  if (firstName || lastName) {
    return ((firstName || '') + ' ' + (lastName || '')).trim()
  } else if (email) {
    return email.replace(/@.*/, '')
  } else {
    return ''
  }
}

export const UserSelector = ({firstName, lastName, email, photoUrl, userOptions}: UserSelectorProps) => {
  const {t} = useTranslation()
  const {state} = useAppState()
  const currentRole = state.me?.user ? getHighestRoleForUser(state.me?.user) : null

  return (
    <UserSelectorModal data-cy="user-selector-modal">
      <LargeUserInfoContainer>
        <Avatar src={photoUrl} alt="profile picture" size="2.63rem" />
        <Text size="M">{getUserName(firstName, lastName, email)}</Text>
      </LargeUserInfoContainer>
      <Divider />
      {userOptions.map(option => (
        <SelectItem key={option.description}>
          <IconText
            icon={<Icon type={option.icon} height="24px" width="24px" />}
            text={<Text size="S">{option.description}</Text>}
            onClick={option.onClick}
          />
          {option.isExternalLink && <Icon type="external-link" height="16px" width="16px" />}
        </SelectItem>
      ))}
      {currentRole != 'BU' || state.me?.accessRights.superuser ? <CustomerSupportInfo /> : <></>}
    </UserSelectorModal>
  )
}

const CustomerSupportInfo = () => {
  const {state} = useAppState()
  const {t} = useTranslation()
  return (
    <>
      {' '}
      <Divider />
      <Text size="XS">{t('common:contactInfo', 'Customer Support')}:</Text>
      <br />
      <a href={`mailto:${supportInfo.email}`}>
        <Text size="XS">{supportInfo.email}</Text>
      </a>
      <br />
      <a href={`tel:${supportInfo.phone}`}>
        <Text size="XS">
          {' '}
          {t('common:phone', 'Tel.')} {supportInfo.phone}{' '}
        </Text>
      </a>
      <br />
      <Text size="XS">
        ({t('common:weekdays', 'Weekdays')} 9-15 {state.me?.user.language !== 'fi' ? '(Helsinki)' : ''} )
      </Text>
    </>
  )
}
