import React from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'

import {Title, Text} from '@fredman/chefstein-shared-frontend-components'
import {ProgressBar} from '../../Atoms/ProgressBar'
import {colors} from '../../../sharedComponents/colors'

const InfoContainer = styled.div<InfoContainerProps>`
  text-align: center;
  align-content: center;
  align-items: center;
  justify-items: center;
  display: inline-grid;
  grid-template-rows: 12.5rem auto;
  grid-row-gap: 0.5rem;
  width: 12.5rem;
  color: ${colors.brand.cobalt};
  cursor: ${props => (!props.onClick ? 'default' : 'pointer')};
`

interface InfoContainerProps {
  onClick?: (e: React.MouseEvent) => void
}

const ProgressBarContainer = styled.div`
  width: 11.25rem;
  height: 11.25rem;
  margin-top: auto;
  margin-bottom: 0.4rem;
`

const ProgressBarContent = styled.div`
  display: inline-grid;
  grid-template-rows: 2.75rem 1rem;
  grid-template-columns: 1fr;
  grid-row-gap: 0.75rem;
  justify-content: center;
  align-items: center;

  & > *:first-child {
    grid-column: 1 / 3;
    grid-row: 1;
  }
`
interface TasksInfoProps {
  total: number
  completed: number
  onClick?: (e: React.MouseEvent) => void
}
const TodaysTasksInfo = ({total, completed, onClick}: TasksInfoProps) => {
  const {t} = useTranslation(['home', 'tasks'])
  const getTodoPercentage = () => {
    if (total > 0) {
      return (1 - completed / total) * 100
    }
    return 0
  }

  const getRemainingTasks = () => {
    return Math.max(total - completed, 0)
  }

  return (
    <InfoContainer onClick={onClick}>
      <ProgressBarContainer>
        <ProgressBar
          percentage={getTodoPercentage()}
          pathColor={colors.brand.signalOrange}
          trailColor={colors.system.lightGrey_10}
          strokeWidth={4}
        >
          <ProgressBarContent>
            <Title level={1}>{getRemainingTasks()}</Title>
            <Text size="M">
              {completed}/{total} {t('tasks:labels.completedAmount', 'completed')}
            </Text>
          </ProgressBarContent>
        </ProgressBar>
      </ProgressBarContainer>
      {total > 0 ? (
        <Title level={4}>{t('home:messages.toDo', 'To do')}</Title>
      ) : (
        <Title level={4}>{t('home:messages.noTasksToday', 'No tasks for today')}</Title>
      )}
    </InfoContainer>
  )
}

export default TodaysTasksInfo
