import React from 'react'
import {useTranslation} from 'react-i18next'
import {isToday} from 'date-fns'
import styled from 'styled-components'

import {Text, ColumnDef, Table, Title} from '@fredman/chefstein-shared-frontend-components'
import {colors} from '../../../sharedComponents/colors'

import {getHumanizedDateString, getHumanizedTimeString, parseStringToDate} from '../../Atoms/Utils'

import CoolingProgress, {isCoolingInProgress, CoolingWarning} from '../../../pages/tasks/GeneralTasks/CoolingProgress'
import {translateTaskGroupName, hasCoolingEventTarget} from '../../../config/utils'

import {useAppState} from '../../../state'
import {BorderedListItem, AdjustableContainer} from '../../Layout/Layout'
import {AllTasksDone, NoTasks} from '../Home/NoContent'
import {translateUmbrellaTypes} from '../../../pages/home/UnscheduledTaskModal'
import {TaskGroupIcon, BlockLabelText} from '../../../pages/home'
import {getTasksUntilNow} from '../../../pages/tasks/utils'

interface TodoTasksListProps {
  scale: number
}

const TodoTasksList = ({scale}: TodoTasksListProps) => {
  const {state} = useAppState()
  const {t} = useTranslation(['tasks', 'home'])
  const todaysTasks = getTasksUntilNow(state.v1.home.site.data.todaysTasks)
  const completedToday = state.v1.home.site.data.completedTodayCount
  const locale = state.site!.locale

  const columnDefs = [
    {
      title: undefined,
      dataKeys: [
        'taskGroup',
        'taskType',
        'status',
        'coolingStartedAt',
        'startTime',
        'targetTime',
        'alarms',
        'cycleLog',
        'latestValue',
        'coolingTimeLimit'
      ],
      widthPercentage: '4.5rem',
      component: (props: any) => (
        // TODO: replace 40+ task group icons in the shared components (same in the task setting page)
        <div style={{display: 'flex', paddingLeft: '1rem'}}>
          {isCoolingInProgress(props?.values[1].id, props?.values[2]) && !props.values[7] ? (
            <CoolingProgress
              startTime={props.values[3] || props.values[4]}
              automaticCoolingEndTime={props.values[7]?.endedAt}
              targetTime={props.values[5]}
              timeLimit={props.values[9]}
              alarms={props.values[6]}
              circleWidth="3em"
              iconWidth="2em"
              fontSize="XXS"
              temperatureValue={props.values[8]?.value}
            />
          ) : (
            <TaskGroupIcon taskGroup={props.values[0]} />
          )}
        </div>
      )
    },
    {
      title: t('tasks:labels.columnNameTaskName', 'Name'),
      dataKeys: ['name', 'taskType', 'status', 'targetTime', 'taskGroup', 'coolingTimeLimit', 'alarms', 'suggestions'],
      component: (props: any) => (
        <div>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <Text size="XS" strong={props.selected} color={colors.system.grey_50} textTransform="uppercase">
              {`${translateUmbrellaTypes(props.values[4].umbrellaCategory)} - ${translateTaskGroupName(
                props.values[4].name
              )}`}
            </Text>
            <div style={{margin: '0.25rem, 0'}}>
              <Text size="M" strong={props.selected}>
                {props.values[0]}
                {props.values[5] && ` - ${props.values[5]} min`}
              </Text>
              {hasCoolingEventTarget(props.values[1], props.values[7]) && (
                <BlockLabelText size="S">{props.values[7][0]}</BlockLabelText>
              )}
            </div>
          </div>
          {isCoolingInProgress(props?.values[1].id, props?.values[2]) && (
            <CoolingWarning
              taskTypeId={props?.values[1].id}
              targetTime={props?.values[3]}
              alarms={props?.values[6]}
              hasMeasurementTarget={hasCoolingEventTarget(props.values[1], props.values[7])}
            />
          )}
        </div>
      )
    },
    {
      title: t('tasks:labels.columnNameDateTime', 'Date & time'),
      dataKeys: ['scheduleInstance', 'createdAt'],
      widthPercentage: '15%',
      component: (props: any) => (
        <Text size="M" strong={props.selected} style={{float: 'right', paddingRight: '1.5rem'}}>
          {isToday(parseStringToDate(props.values[0] || props.values[1]))
            ? getHumanizedTimeString(parseStringToDate(props.values[0] || props.values[1]), locale)
            : getHumanizedDateString(parseStringToDate(props.values[0] || props.values[1]), locale)}
        </Text>
      )
    }
  ] as ColumnDef[]

  return (
    <ScaledView scale={scale}>
      <BorderedListItem>
        <AdjustableContainer padding="1em">
          <Title level={2}>{t('tasks:labels.tabHeadingToDo', 'To-do list')}</Title>
        </AdjustableContainer>
      </BorderedListItem>
      {todaysTasks.length > 0 ? (
        <Table
          data-cy="today-table"
          columnDefs={columnDefs}
          data={todaysTasks}
          rowHoverBackgroundColor={colors.system.white}
          hideHeader
        />
      ) : completedToday === 0 ? (
        <NoTasks />
      ) : (
        <AllTasksDone />
      )}
    </ScaledView>
  )
}

const ScaledView = styled.div<TodoTasksListProps>`
  transform: ${props => `scale(${props.scale})`};
  transform-origin: 0 0;

  width: ${props => `${100 / props.scale}%`};
  height: ${props => `${100 / props.scale}%`};
`

export default TodoTasksList
