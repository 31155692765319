import {Alarm, AlarmSubType, AlarmType} from '../../state/alarms/state'
import {TFunction} from 'i18next'
import {minutesToDurationString} from '../../Components/Atoms/Utils'
import {Site} from '../../state/state'
import {convertUnit, localNumberFormat} from '../../config/utils'
import {DIVIDER_TEXT, IconPalette} from '@fredman/chefstein-shared-frontend-components'

export const alarmUnit = (
  t: TFunction,
  type: AlarmType,
  subtype: AlarmSubType,
  customUnit: string | null,
  units: Units
): string | undefined => {
  switch (type) {
    case AlarmType.Temperature:
      return `°${units.temperature}`
    case AlarmType.Cooling:
      if (subtype === AlarmSubType.CoolingTime) {
        return '' //Unit is encompassed in the time value itself
      }
      return `°${units.temperature}`
    case AlarmType.Waste:
      return units.weight
    case AlarmType.Hygiene:
      return `RLU`
    case AlarmType.Manual:
      return ` ${customUnit}`
    case AlarmType.Humidity:
      return '%'
    case AlarmType.Battery:
      return '%'
    case AlarmType.Calibration:
      return t('alarms:labels.calibrationDaysLeft', ' days left')
    case AlarmType.Disconnect:
      return undefined //Unit is encompassed in the time value itself
  }
  return undefined
}

export const alarmLimitUnit = (
  t: TFunction,
  type: AlarmType,
  subtype: AlarmSubType,
  customUnit: string | null,
  units: Units
): string | undefined => {
  switch (type) {
    case AlarmType.Waste:
    case AlarmType.Hygiene:
    case AlarmType.Manual:
    case AlarmType.Battery:
    case AlarmType.Calibration:
    case AlarmType.Temperature:
    case AlarmType.Humidity:
      return alarmUnit(t, type, subtype, customUnit, units)
    case AlarmType.Cooling:
      if (subtype === AlarmSubType.CoolingTime) {
        return ` ${t('common:dateNames.tiny.min', 'min')}`
      }
      return alarmUnit(t, type, subtype, customUnit, units)
    case AlarmType.Disconnect:
      return undefined // Disconnect alarms have no limits
  }
  return undefined
}

export const alarmValueFormatter = (value: string | number, type: AlarmType, subtype: AlarmSubType, site: Site) => {
  switch (type) {
    case AlarmType.Battery:
      return `${value}`
    case AlarmType.Calibration:
      return `${value}`
    case AlarmType.Cooling:
      if (subtype === AlarmSubType.CoolingTime) {
        return typeof value === 'number' ? minutesToDurationString(value, true) : value
      }
      return value
    case AlarmType.Disconnect:
      return typeof value === 'number' ? minutesToDurationString(value, true) : value
    case AlarmType.Temperature:
      const temperatureValue = localNumberFormat(site.locale, convertUnit(site, value as number))
      return `${temperatureValue}`
    case AlarmType.Humidity:
      return localNumberFormat(site.locale, value)
    default:
      return String(value)
  }
}

export const limitFormatter = (t: TFunction, alarm: Alarm, units: Units) => {
  if (alarm.limits === null) {
    return '-'
  }
  const realUnit = alarmLimitUnit(t, alarm.type, alarm.subtype, alarm.unit, units)

  if (alarm.limits.maxValue !== null && alarm.limits.minValue !== null) {
    if (alarm.type === AlarmType.Cooling && alarm.subtype !== AlarmSubType.CoolingTime) {
      return `${t('appliances:labels.coolerStartTemperature', 'Start temp.')} ${
        alarm.limits.maxValue
      } ${realUnit} ${DIVIDER_TEXT} ${t('appliances:labels.coolingEndTemperature', 'Ending temperature')} ${
        alarm.limits.minValue
      } ${realUnit}`
    }
    return `Min ${alarm.limits.minValue} ${realUnit} ${DIVIDER_TEXT} ${alarm.limits.maxValue} ${realUnit} Max`
  } else if (alarm.limits.maxValue !== null) {
    return `Max ${alarm.limits.maxValue} ${realUnit}`
  } else if (alarm.limits.minValue !== null) {
    return `Min ${alarm.limits.minValue} ${realUnit}`
  } else {
    return '-'
  }
}

export const alarmNameFormatter = (t: TFunction, name: string, role: string | null) => {
  const translateRole = (t: TFunction, role: string): string => {
    switch (role) {
      case 'rinse':
        return t('alarms:labels.sensorRole.rinse', 'Rinse')
      case 'wash':
        return t('alarms:labels.sensorRole.wash', 'Wash')
      case 'prewash':
        return t('alarms:labels.sensorRole.prewash', 'Prewash')
      default:
        return role
    }
  }

  if (role && ['rinse', 'wash', 'prewash'].includes(role)) {
    return `${name} - ${translateRole(t, role)}`
  } else {
    return name
  }
}

export interface Units {
  temperature: string
  weight: string
}

export const dateValueFormatter = (dateString: string, locale: string) => {
  const date = new Date(dateString).toLocaleDateString(locale)
  const time = new Date(dateString).toLocaleTimeString(locale, {
    hour: '2-digit',
    minute: '2-digit'
  })
  return `${date} - ${time}`
}

export const resolverNameFormatter = (user: {lastName: string; firstName: string} | null): string => {
  if (user === null || user === undefined) {
    return ''
  }
  return `${user.firstName} ${user.lastName}`
}

// Todo: Add all relevant icons to shared components
export const alarmIconPicker = (type: AlarmType): IconPalette => {
  switch (type) {
    case AlarmType.Temperature:
      return IconPalette.Temperature
    case AlarmType.Humidity:
      return IconPalette.Humidity
    case AlarmType.Cooling:
      return IconPalette.Cooling
    case AlarmType.Hygiene:
      return IconPalette.SurfaceHygiene
    case AlarmType.Waste:
      return IconPalette.Waste
    case AlarmType.Manual:
      return IconPalette.Manual
    case AlarmType.Calibration:
      return IconPalette.Maintenance
    case AlarmType.Disconnect:
      return IconPalette.Disconnected
    case AlarmType.Battery:
      return IconPalette.Sensor
    case AlarmType.SensorMalfunction:
      return IconPalette.Sensor
    default:
      return IconPalette.AlertTriangle
  }
}

export const alarmTypeToTranslation = (t: TFunction, type: string) => {
  const mapping: {[index: string]: string} = {
    TEMPERATURE: t('alarms:labels.type.temperature', 'Temperature'),
    HUMIDITY: t('alarms:labels.type.humidity', 'Humidity'),
    COOLINGTIME: t('alarms:labels.type.temperature', 'Temperature'),
    COOLING: t('alarms:labels.type.cooling', 'Cooling'),
    WASTE: t('alarms:labels.type.waste', 'Waste'),
    HYGIENE: t('alarms:labels.type.surfaceHygiene', 'Surface Hygiene'),
    BATTERY: t('alarms:labels.type.battery', 'Battery'),
    CALIBRATION: t('alarms:labels.type.calibration', 'Calibration'),
    DISCONNECT: t('alarms:labels.type.disconnect', 'Disconnect'),
    MANUAL: t('alarms:labels.type.manual', 'Manual')
  }
  return mapping[type]
}

export const alarmSubtypeToTranslation = (t: TFunction, type: AlarmType, subtype: AlarmSubType) => {
  switch (type) {
    case AlarmType.Hygiene:
    case AlarmType.Waste:
    case AlarmType.Manual:
    case AlarmType.Temperature:
    case AlarmType.Humidity:
      switch (subtype) {
        case AlarmSubType.High:
          return t('alarms:labels.subtype.high', 'High alarm')
        case AlarmSubType.Low:
          return t('alarms:labels.subtype.low', 'Low alarm')
        default:
          return undefined
      }
    case AlarmType.Cooling:
      switch (subtype) {
        case AlarmSubType.High:
          return t('alarms:labels.subtype.coolingHigh', 'End temperature too high')
        case AlarmSubType.Low:
          return t('alarms:labels.subtype.coolingLow', 'Start temperature too low')
        case AlarmSubType.CoolingTime:
          return t('alarms:labels.subtype.coolingTime', 'Cooling time exceeded')
        default:
          return undefined
      }
    case AlarmType.Calibration:
      return t('alarms:labels.subtype.calibrationExpiring', 'Expiring')
    default:
      return undefined
  }
}

export const alarmStatus = (t: TFunction, status: string, isResolvable: boolean, type: AlarmType): string => {
  if (!isResolvable) {
    switch (type) {
      case AlarmType.Disconnect:
        return t('alarms:label.state.waitingSensorReconnect', 'Waiting for the sensor to reconnect.')
      case AlarmType.Temperature:
        return t('alarms:label.state.waitingTemperatureInRange', 'Temperature is outside targets')
      case AlarmType.Humidity:
        return t('alarms:label.state.waitingHumidityInRange', 'Humidity is outside targets')
      default:
        break
    }
  }
  return status === 'active' ? t('alarms:label.state.active', 'active') : t('alarms:label.state.resolved', 'resolved')
}
