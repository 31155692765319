import React, {useEffect, useCallback, useState} from 'react'
import {useNavigation} from 'react-navi'
import styled from 'styled-components'
import {Title, SharedIcon, IconPalette} from '@fredman/chefstein-shared-frontend-components'
import {colors} from '../../../sharedComponents/colors'

import {useAppState} from '../../../state'
import {CGIWhiteLogo} from '../../../Assets/Icons/BrandIcons'
import {getHumanizedWeekString, getHumanizedDateString, getHumanizedTimeString} from '../../Atoms/Utils'

const InfoView = () => {
  const {state} = useAppState()
  const nav = useNavigation()
  const [clock, setClock] = useState(new Date())

  const onKeyPressed = useCallback((event: {keyCode: number}) => {
    if (event.keyCode === 27) {
      // esc key
      console.log('esc pressed')
      nav.navigate('/')
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', onKeyPressed, false)

    return () => {
      document.removeEventListener('keydown', onKeyPressed, false)
    }
  }, [])

  useEffect(() => {
    let clockTimer = setInterval(() => {
      setClock(new Date())
    }, 1 * 1000) // update every seconds

    return () => {
      clearInterval(clockTimer)
    }
  }, [])

  return (
    <HeaderContainer>
      <LogoWrapper onClick={() => nav.navigate('/')}>
        <CGIWhiteLogo width="10em" />
      </LogoWrapper>
      <Title level={3} style={{marginRight: 'auto', fontSize: '2vh'}} strong>
        {state.site?.name}
      </Title>
      <TimeContainer>
        <SharedIcon icon={IconPalette.Late} fill={colors.system.white} width="2.5vh" height="2.5vh" />
        <Title level={1} style={{fontSize: '3vh'}}>
          {getHumanizedTimeString(clock, state.site!.locale)}
        </Title>
        <VerticalLine />
        <Title level={1} style={{fontSize: '3vh'}}>
          {getHumanizedWeekString(clock)} {getHumanizedDateString(clock, state.site!.locale)}
        </Title>
      </TimeContainer>
    </HeaderContainer>
  )
}

const HeaderContainer = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
`

const LogoWrapper = styled.div`
  background-color: ${colors.brand.cobalt};
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 2.4vh;
  cursor: pointer;
  &:active {
    transform: translateY(1px);
  }
  padding: 0 4rem;
`

const TimeContainer = styled.div`
  padding: 0 2rem;
  display: flex;
  gap: 1rem;
  align-items: center;
`

const VerticalLine = styled.div`
  border-left: 1px solid white;
  align-self: normal;
`

export default () => {
  const {state} = useAppState()
  return !!state.me && !!state.currentUser ? <InfoView /> : null
}
