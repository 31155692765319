import React from 'react'
import {ValueInputWithRange} from '@fredman/chefstein-shared-frontend-components'
import {useTranslation} from 'react-i18next'

import PerformTaskForm from './PerformTaskForm'
import PerformTaskTitle from './PerformTaskTitle'
import {useAppState} from '../../../state'
import {convertUnit, tempUnitStr, handleFloatInput} from '../../../config/utils'
import {PerformableTask} from '../../../state/performTask/state'
import {FormContainer} from './PerformCoolerCoolingTask'

interface Props {
  task: PerformableTask
  setValue: any
  onAssetSaved: () => void
  payload: any
  handleNumberBlur: (event: any) => void
  handleRemove: () => void
}

const PerformEquipmentTemperatureTask = ({
  task,
  setValue,
  onAssetSaved,
  payload,
  handleNumberBlur,
  handleRemove
}: Props) => {
  const {state} = useAppState()
  const {t} = useTranslation('tasks')

  return (
    <FormContainer>
      <PerformTaskTitle task={task} />
      <ValueInputWithRange
        name="value-input"
        value={payload.value}
        label={t('tasks:labels.measuredTemperature', 'Measured temperature')}
        onChange={e => setValue('value', handleFloatInput(e.target.value))}
        onBlur={e => handleNumberBlur(e.target.value)}
        unit={tempUnitStr(state.site!.temperatureUnit)}
        minValue={convertUnit(state.site!, task.minValue)}
        maxValue={convertUnit(state.site!, task.maxValue)}
        message={{
          success: t('tasks:inputWithRange.withinTarget', 'The value is within target limits'),
          error: t('tasks:inputWithRange.not.withInTarget', 'The value is not within target')
        }}
        required
      />
      <PerformTaskForm
        onAssetSaved={onAssetSaved}
        onInputChange={setValue}
        payload={payload}
        handleRemove={!!task.scheduleInstance ? handleRemove : undefined}
        setValue={setValue}
      />
    </FormContainer>
  )
}

export default PerformEquipmentTemperatureTask
