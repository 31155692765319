import React from 'react'
import {ValueInputWithRange} from '@fredman/chefstein-shared-frontend-components'
import {useTranslation} from 'react-i18next'

import InputWithTags from './InputWithTags'
import PerformTaskForm from './PerformTaskForm'
import {useAppState} from '../../../state'

import {convertUnit, tempUnitStr, handleFloatInput} from '../../../config/utils'
import {PerformableTask} from '../../../state/performTask/state'
import PerformTaskTitle from './PerformTaskTitle'
import {FormContainer} from './PerformCoolerCoolingTask'

interface Props {
  task: PerformableTask
  setValue: any
  onAssetSaved: () => void
  payload: any
  handleRemove: () => void
  handleNumberBlur: (event: any) => void
}

const PerformTemperatureTask = ({task, setValue, onAssetSaved, payload, handleRemove, handleNumberBlur}: Props) => {
  const {state} = useAppState()
  const {t} = useTranslation('tasks')

  return (
    <FormContainer>
      <PerformTaskTitle task={task} />
      <ValueInputWithRange
        name="value-input"
        id="value-input"
        value={payload.value}
        label={t('tasks:labels.measuredTemperature', 'Measured temperature')}
        onChange={e => setValue('value', handleFloatInput(e.target.value))}
        onBlur={e => handleNumberBlur(e.target.value)}
        unit={tempUnitStr(state.site!.temperatureUnit)}
        minValue={convertUnit(state.site!, task.minValue)}
        maxValue={convertUnit(state.site!, task.maxValue)}
        message={{
          success: t('tasks:inputWithRange.withinTarget', 'The value is within target limits'),
          error: t('tasks:inputWithRange.not.withInTarget', 'The value is not within target')
        }}
        required
      />
      <InputWithTags
        savedSuggestions={payload.suggestions}
        defaultSuggestions={task.defaultSuggestions || []}
        onChange={setValue}
        required
      />
      <PerformTaskForm
        onInputChange={setValue}
        onAssetSaved={onAssetSaved}
        payload={payload}
        handleRemove={!!task.scheduleInstance ? handleRemove : undefined}
        setValue={setValue}
      />
    </FormContainer>
  )
}

export default PerformTemperatureTask
