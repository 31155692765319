import {ApplianceDataProps, IconPalette} from '@fredman/chefstein-shared-frontend-components'
import {EquipmentTypeKey, SensorRole} from '../../state/rest'
import {Appliance, CoolerAlarmRule, MeasurementType, Sensor} from '../../state/appliances/state'
import {convertUnit, getWeightUnitStr, handleWeightValue, localNumberFormat, tempUnitStr} from '../../config/utils'
import {Site} from '../../state/state'
import {TFunction} from 'i18next'

export const ERROR_NOTIFICATION_DURATION_MS = 6000

export type AppliancePhaseData = Omit<ApplianceDataProps, 'value' | 'minValue' | 'maxValue'> & {
  value?: (number | string) | React.ReactNode
  minValue?: (number | string) | React.ReactNode
  maxValue?: (number | string) | React.ReactNode
  targetValue?: (number | string) | React.ReactNode
  idleLimit?: number
  sensorUuid: string
  updatedAt?: string
  maxTime?: number
  cycleStartedAt?: string
  cycleEndedAt?: string
  isCooling?: boolean
  maxValueLabelText?: string
}

export const getApplianceData = (t: TFunction, appliance?: Appliance, site?: Site): AppliancePhaseData[] => {
  if (!appliance || !appliance.sensors || !site) {
    return []
  }

  return appliance.sensors
    ?.map(sensor => {
      const measurementType =
        appliance.type === EquipmentTypeKey.WASTE
          ? sensor.measurementTypes?.weight
          : sensor.measurementTypes?.temperature
      const alarmRule = measurementType?.alarmRule as CoolerAlarmRule
      const convertedValue =
        appliance.type === EquipmentTypeKey.WASTE
          ? handleWeightValue(site, measurementType?.latestValue?.value)
          : convertUnit(site, measurementType?.latestValue?.value)
      const formattedValue = localNumberFormat(site.locale, convertedValue)
      const unit =
        appliance.type === EquipmentTypeKey.WASTE
          ? getWeightUnitStr(site.weightUnit)
          : tempUnitStr(site.temperatureUnit)
      const formattedUnit = formatUnit(
        unit,
        appliance.type === EquipmentTypeKey.WASTE ? MeasurementType.weight : MeasurementType.temperature
      )
      const isLorawanStorageOrFreezer =
        checkLorawanSensor(sensor) &&
        (appliance.type === EquipmentTypeKey.STORAGE || appliance.type === EquipmentTypeKey.FREEZER)
      const title = isLorawanStorageOrFreezer
        ? t('appliances:labels.temperature', 'Temperature')
        : getSensorRoleTitle(
            t,
            (appliance.sensors && appliance.sensors.length > 1) || appliance.type === EquipmentTypeKey.DISHWASHER
              ? sensor.role
              : undefined
          )
      const applianceData: AppliancePhaseData[] = [
        {
          sensorUuid: sensor.id,
          value: appliance.suspended ? undefined : formattedValue,
          updatedAt: measurementType?.latestValue?.timestamp,
          unit,
          minValue: alarmRule?.minValue ?? undefined,
          maxValue: alarmRule?.maxValue ?? undefined,
          targetValue: alarmRule?.targetValue ?? undefined,
          idleLimit: alarmRule?.idleLimit ?? undefined,
          minUnit: formattedUnit,
          maxUnit: formattedUnit,
          title: title?.toUpperCase(),
          maxTime: alarmRule?.maxTime,
          cycleStartedAt: sensor.cycleStartedAt,
          cycleEndedAt: sensor.cycleEndedAt,
          hasAlarm: Boolean(measurementType?.hasActiveAlarm),
          isCooling: Boolean(sensor.isCooling),
          maxValueLabelText: getIsCoolerAppliance(appliance) ? t('appliances:labels.target', 'Target') : undefined
        }
      ]
      // Add relative humidity data for LoRaWAN storage and freezer
      if (isLorawanStorageOrFreezer) {
        const humidityMeasurementType = sensor.measurementTypes?.relativeHumidity
        const formattedHumidityValue = localNumberFormat(site.locale, humidityMeasurementType?.latestValue?.value)
        const humidityAlarmRule = humidityMeasurementType?.alarmRule
        const humidityData = {
          sensorUuid: sensor.id,
          value: appliance.suspended ? undefined : formattedHumidityValue,
          updatedAt: humidityMeasurementType?.latestValue?.timestamp,
          unit: '%',
          title: t('appliances:labels.humidity', 'Humidity').toUpperCase(),
          minValue: humidityAlarmRule?.minValue ?? undefined,
          maxValue: humidityAlarmRule?.maxValue ?? undefined,
          minMaxValueDivider: '–',
          hasAlarm: Boolean(humidityMeasurementType?.hasActiveAlarm)
        }
        applianceData.push(humidityData)
      }
      return applianceData
    })
    .flat()
}

const formatUnit = (unit?: string, measurementType?: MeasurementType) => {
  switch (measurementType) {
    case MeasurementType.weight:
      return ` ${unit}`
    default:
      return unit
  }
}

export const getSensorRoleTitle = (t: TFunction, role?: SensorRole) => {
  switch (role) {
    case SensorRole.prewash:
      return t('appliances:labels.prewash', 'Prewash')
    case SensorRole.wash:
      return t('appliances:labels.wash', 'Wash')
    case SensorRole.rinse:
      return t('appliances:labels.rinse', 'Rinse')
    default:
      return undefined
  }
}

export const isApplianceCoolingInProgress = (appliance: Appliance) => {
  return Boolean(appliance.sensors?.[0]?.isCooling)
}

export const hasApplianceCoolingLowAlarm = (appliance: Appliance) => {
  const temperatureMeasurementType = appliance.sensors?.[0]?.measurementTypes?.temperature
  return isApplianceCoolingInProgress(appliance) && Boolean(temperatureMeasurementType?.hasActiveAlarm)
}

export const checkLorawanSensor = (sensor?: Sensor) => {
  return Boolean(sensor?.context?.devEui)
}

export const checkLorawanAppliance = (appliance?: Appliance) => {
  const sensor = appliance?.sensors?.[0]
  return checkLorawanSensor(sensor)
}

export const getIsCoolerAppliance = (appliance: Appliance) => Boolean(appliance.type === EquipmentTypeKey.COOLER)

export const getApplianceIcon = (applianceType: EquipmentTypeKey): IconPalette => {
  switch (applianceType) {
    case EquipmentTypeKey.STORAGE:
      return IconPalette.Fridge
    case EquipmentTypeKey.FREEZER:
      return IconPalette.Freezer
    case EquipmentTypeKey.WASTE:
      return IconPalette.Scale
    case EquipmentTypeKey.DISHWASHER:
      return IconPalette.Dishwasher
    case EquipmentTypeKey.COOLER:
      return IconPalette.Cooler
    default:
      return IconPalette.Alarm
  }
}
