import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {MainLayoutWithoutStretch, MainContent} from '../Layout/Layout'
import {Baseline, InvisibleContainer} from '../Layout/Grid'
import {H3} from '../Atoms/Typography'
import {useAppState} from '../../state'
import {ListItem, ListContainer} from '../Atoms/Lists'
import {useNavigation} from 'react-navi'
import {IconButton} from '../Atoms/Buttons'
import {IconAddItem} from '../../Assets/Icons/LargeIcons'
import {IconSettings} from '../../Assets/Icons/TinyIcons'
import {colors} from '../../sharedComponents/colors'
import {GoBackHeaderTitle, HeaderRow} from '../Molecules/ViewComponents/ViewHeader'
import {taskGroupNameArgs} from '../../config/utils'
import {Helmet} from 'react-navi-helmet-async'
import {sortBy} from 'lodash'
import {Loader} from '../../Routes'
import {ErrorMessage} from '../Molecules/Errors/ErrorMessage'
import {SharedIcon} from '@fredman/chefstein-shared-frontend-components'
import {taskGroupIconPicker} from '../../pages/tasks/utils'

export default function EditTaskGroups() {
  const nav = useNavigation()
  const {state, actions} = useAppState()
  const {t} = useTranslation('tasks')

  useEffect(() => {
    const setup = async () => {
      actions.v1.settings.taskGroups.getTaskGroups()
    }
    setup()
  }, [actions.v1.settings.taskGroups])

  const {data: taskGroups, error, pending} = state.v1.settings.taskGroups
  return (
    <MainLayoutWithoutStretch>
      <Helmet title={t('common:routes.taskGroupSettings', 'Task groups')} />
      <Loader show={pending} />
      {!pending && (
        <MainContent variant="white">
          <Baseline>
            <HeaderRow>
              <GoBackHeaderTitle
                label={t('tasks:labels.taskGroupSettings', 'Task groups')}
                path={'/edit/tasks'}
                backLabel={t('tasks:labels.taskSettings', 'Tasks settings')}
              />
            </HeaderRow>
            {error === null ? (
              <>
                <ListContainer>
                  {sortBy(taskGroups, [
                    tg => {
                      return t(...taskGroupNameArgs(tg.name))
                    }
                  ]).map(tg => {
                    return (
                      <ListItem
                        key={tg.id}
                        action={
                          !tg.fixed && (
                            <IconButton
                              buttonProps={{
                                onClick: e => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                  nav.navigate('/edit/taskGroup/' + tg.id)
                                }
                              }}
                              icon={<IconSettings color={colors.system.grey_1} />}
                            />
                          )
                        }
                        icon={<SharedIcon icon={taskGroupIconPicker(tg.icon)} />}
                      >
                        <H3 data-cy="taskgroup-list-row-title">{t(...taskGroupNameArgs(tg.name))}</H3>
                      </ListItem>
                    )
                  })}
                </ListContainer>
                <InvisibleContainer data-cy="add-new-taskgroup">
                  <IconButton
                    icon={<IconAddItem />}
                    description={t('tasks:actions.addTaskGroup', 'Add new task group')}
                    buttonProps={{onClick: () => nav.navigate('/create/taskGroup')}}
                  />
                </InvisibleContainer>{' '}
              </>
            ) : (
              <ErrorMessage message={error.message} />
            )}
          </Baseline>
        </MainContent>
      )}
    </MainLayoutWithoutStretch>
  )
}
