import {baseApi} from '../rest'
import qs from 'query-string'
import _ from 'lodash'
import {FilterOptions} from '../tasks/effects'
import {Alarm} from './state'
import {isEmptyValue} from '../performTask/effects'

export const alarmsApi = {
  ...baseApi,
  async getAlarm(alarmId: string, siteId: string): Promise<Alarm> {
    const request = await this.doRequest({
      path: `/v1/alarms/${alarmId}`,
      site: siteId
    })
    const response = await request.get()
    return (response as unknown) as Alarm
  },
  async resolveAlarm(alarmId: string, resolution: string, resolvedBy: string, siteId: string): Promise<Alarm> {
    const request = await this.doRequest({
      path: `/v1/alarms/${alarmId}`,
      site: siteId,
      payload: _.omitBy({resolution, resolvedBy}, isEmptyValue)
    })
    const response = await request.put()
    return response.data as Alarm
  },
  async getMoreAlarmsForSite(siteId: string, type: string, options?: FilterOptions): Promise<any> {
    const qstring = options ? qs.stringify({...options, type}, {arrayFormat: 'comma'}) : undefined
    const path = `/v2/alarms/site/${siteId}/paging/loadMore?${qstring}`
    const request = await this.doRequest({path})
    const response = await request.get()
    return (response as unknown) as any
  },
  async getAlarmsForSiteWithPagination(siteId: string, options?: FilterOptions): Promise<any> {
    const qstring = options ? qs.stringify(options, {arrayFormat: 'comma'}) : undefined
    const path = `/v2/alarms/site/${siteId}/paging?${qstring}`
    const request = await this.doRequest({path})
    const response = await request.get()
    return (response as unknown) as any
  }
}
