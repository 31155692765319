import React from 'react'
import {IconPalette, SharedIcon} from '@fredman/chefstein-shared-frontend-components'
import {colors} from '../../../sharedComponents/colors'
import styled, {css} from 'styled-components'
import {Text, DataField} from '@fredman/chefstein-shared-frontend-components'
import {useTranslation} from 'react-i18next'
import {roundToTwoDecimal} from '../../../pages/tasks/CompletedTasks'
import {SmartAsset} from '../../../Components/Atoms/ImageLoader'
import {AlarmSubType} from '../../../state/alarms/state'
import {MeasurementMethodOption, TaskAlarmDetails} from '../../../state/home/site/state'
import {hasAlarmType} from '../../../pages/tasks/CompletedTasks'
import {capitalizeFirstLetter} from '../../../Components/Atoms/Utils'
import {TFunction} from 'i18next'
import {Subtask} from 'state/performTask/state'

const commonStyles = css`
  display: grid;
  margin: 1rem 0;
  grid-template-columns: 4% 55% auto 15%;
  grid-template-rows: auto auto;
  row-gap: 1rem;
`
const deleteGridRow = css`
  '. deleteEvent . .';
`

const GridDataField = ({gridArea, title, content}: any) => {
  return (
    <div style={gridArea}>
      <DataField valueSize="S" labelSize="XS" label={title} value={content} />
    </div>
  )
}

const WasteReasonArea = ({reason}: any) => {
  const {t} = useTranslation('tasks')
  return (
    <GridDataField
      gridArea={{gridArea: 'reason'}}
      title={t('tasks:labels.reason', 'Reason for waste')}
      content={reason || '-'}
    />
  )
}

const CompletedByArea = ({completedBy}: any) => {
  const {t} = useTranslation('tasks')
  return (
    <GridDataField
      gridArea={{gridArea: 'completedBy'}}
      title={t('tasks:labels.completedByArea', 'Completed by')}
      content={completedBy || '-'}
    />
  )
}

const NoteArea = ({notes}: any) => {
  const {t} = useTranslation('tasks')
  return <GridDataField gridArea={{gridArea: 'note'}} title={t('tasks:labels.note', 'Note')} content={notes || '-'} />
}

const SubtasksArea = ({tasks}: any) => {
  const {t} = useTranslation('tasks')
  const subtasks = Object.prototype.toString.call(tasks) === '[object Array]' ? tasks.join(' / ') : tasks
  return (
    <GridDataField
      gridArea={{gridArea: 'subtasks'}}
      title={t('tasks:labels.subtasks', 'Subtasks')}
      content={subtasks || '-'}
    />
  )
}

// Todo : This was seperated so that at some point it could be modified to look better etc. table or smthing
const SubtasksAreaWithActors = ({tasks}: any) => {
  const {t} = useTranslation('tasks')
  const subtasks: Subtask[] = tasks
    .map((task: Subtask) => `${task.subtaskName} (${task.completedBy || 'N/A'})`)
    .join(' / \n')
  return (
    <GridDataField
      gridArea={{gridArea: 'subtasks'}}
      title={t('tasks:labels.subtasks', 'Subtasks')}
      content={subtasks || '-'}
    />
  )
}

const CompletedTimeArea = ({completedTime}: any) => {
  const {t} = useTranslation('tasks')
  return (
    <GridDataField
      gridArea={{gridArea: 'completedTime'}}
      title={t('tasks:labels.completedTime', 'Completed time')}
      content={completedTime || '-'}
    />
  )
}

const CompletedTimeDurationArea = ({startTime, endTime, duration, alarms}: any) => {
  const {t} = useTranslation('tasks')
  const coolingAlarms = Array.isArray(alarms) ? (alarms as TaskAlarmDetails[]) : undefined
  const hasCoolingTimeExtended = coolingAlarms ? hasAlarmType(coolingAlarms, AlarmSubType.CoolingTime) : false
  const content =
    startTime && endTime && typeof duration === 'number'
      ? `${startTime} -> ${endTime} (${duration} ${t('tasks:labels.minutes', 'minutes')})`
      : '-'
  const durationContent = (
    <Text size="S" color={hasCoolingTimeExtended ? colors.system.red : colors.brand.cobalt}>
      {content}
    </Text>
  )
  return (
    <GridDataField
      gridArea={{gridArea: 'completedTime'}}
      title={`${t('tasks:labels.completedTime', 'Completed time')} (${t('tasks:labels.duration', 'Duration')})`}
      content={durationContent}
    />
  )
}

const getMeasurementMethodLabel = (method: any, t: TFunction) => {
  const getTranslatedLabel = (label?: string) => {
    switch (label) {
      case MeasurementMethodOption.MANUAL:
        return t('tasks:labels.manualMeasuring', 'manual')
      case MeasurementMethodOption.DEVICE:
        return t('tasks:labels.deviceMeasuring', 'device')
      default:
        return '-'
    }
  }
  const getCapitalizedLabel = (method: MeasurementMethodOption) => {
    const label = getTranslatedLabel(method)
    return capitalizeFirstLetter(label)
  }

  const formatMethodLabel = (methods: string[]) => {
    const first = getTranslatedLabel(methods[0])
    const second = getTranslatedLabel(methods[1])
    return first === second
      ? capitalizeFirstLetter(first)
      : capitalizeFirstLetter(`${first} ${t('common:labels.and', 'and')} ${second}`)
  }

  if (Array.isArray(method)) {
    return method.length === 2 ? formatMethodLabel(method) : getCapitalizedLabel(method[0])
  }
  return '-'
}

const MeasurementTargetArea = ({target}: any) => {
  const {t} = useTranslation('tasks')
  return (
    <GridDataField
      gridArea={{gridArea: 'measurementTarget'}}
      title={t('tasks:labels.measurementValueTarget', 'Measurement target')}
      content={target || '-'}
    />
  )
}

const MeasurementMethodArea = ({method}: any) => {
  const {t} = useTranslation(['tasks', 'common'])
  const label = getMeasurementMethodLabel(method, t)
  return (
    <GridDataField
      gridArea={{gridArea: 'measurementMethod'}}
      title={t('tasks:labels.measurementMethod', 'Measurement method')}
      content={label}
    />
  )
}

const ResultTargetAreaForCooling = ({min, max, unit}: any) => {
  const {t} = useTranslation('tasks')
  const roundedMin = typeof min === 'number' ? roundToTwoDecimal(min) : undefined
  const roundedMax = typeof max === 'number' ? roundToTwoDecimal(max) : undefined
  const content =
    roundedMin !== undefined && roundedMax !== undefined
      ? `Min ${roundedMin}°${unit} -> ${roundedMax}°${unit} Max`
      : '-'
  return <GridDataField gridArea={{gridArea: 'limit'}} title={t('tasks:labels.limit', 'Limit')} content={content} />
}

const PeakTemperatureArea = ({peak, alarms, unit}: any) => {
  const {t} = useTranslation('tasks')
  const coolingAlarms = Array.isArray(alarms) ? (alarms as TaskAlarmDetails[]) : undefined
  const hasLowLimitAlarm = coolingAlarms ? hasAlarmType(coolingAlarms, AlarmSubType.Low) : false
  const formatedPeak = typeof peak === 'number' ? String(roundToTwoDecimal(peak)) : ''
  const content = `${formatedPeak}°${unit}`
  const peakContent = (
    <Text size="S" color={hasLowLimitAlarm ? colors.system.red : colors.brand.cobalt}>
      {content}
    </Text>
  )
  return (
    <GridDataField
      gridArea={{gridArea: 'peakTemperature'}}
      title={t('tasks:labels.peakTemperature', 'Peak temperature')}
      content={peakContent}
    />
  )
}

const ResultTargetAreaForMinMax = ({min, max, unit, isTemperature}: any) => {
  const {t} = useTranslation('tasks')
  const roundedMin = typeof min === 'number' ? roundToTwoDecimal(min) : undefined
  const roundedMax = typeof max === 'number' ? roundToTwoDecimal(max) : undefined
  const displayUnit = !!isTemperature ? `°${unit}` : ` ${unit}`
  const content =
    roundedMin !== undefined && roundedMax !== undefined
      ? `Min ${roundedMin}${displayUnit} / ${roundedMax}${displayUnit} Max`
      : '-'
  return <GridDataField gridArea={{gridArea: 'limit'}} title={t('tasks:labels.limit', 'Limit')} content={content} />
}

const ResultTargetArea = ({max, unit}: any) => {
  const {t} = useTranslation('tasks')
  const measurementUnit = unit ? unit : t('tasks:labels.unitPlaceholder', 'unit')
  const roundedMax = typeof max === 'number' ? roundToTwoDecimal(max) : undefined
  const content = roundedMax !== undefined ? `Max ${roundedMax} ${measurementUnit}` : '-'
  return <GridDataField gridArea={{gridArea: 'limit'}} title={t('tasks:labels.limit', 'Limit')} content={content} />
}

const ImageBlock = ({image}: any) => {
  const {t} = useTranslation('tasks')
  return (
    <div style={{gridArea: 'image'}}>
      <div>
        <Text size="XS" color={colors.system.grey_50}>
          {t('tasks:labels.imageOrVideo', 'Image / video')}
        </Text>
      </div>
      <div style={{margin: '0.25rem 0 0 0'}}>{image ? <SmartAsset asset={image} /> : <Text size="S">-</Text>}</div>
    </div>
  )
}

const DeleteEvent = ({deleteHandler}: any) => {
  const {t} = useTranslation('tasks')
  return (
    <DeleteAction onClick={deleteHandler} style={{gridArea: 'deleteEvent'}}>
      <SharedIcon icon={IconPalette.Waste} width="16px" height="16px" fill={colors.brand.brightRed} />
      <Text size="S" color={colors.brand.brightRed} style={{paddingLeft: 6}}>
        {t('tasks:labels.deleteTask', 'Delete task')}
      </Text>
    </DeleteAction>
  )
}

const DeleteAction = styled.a`
  cursor: pointer;
`

const AutomaticCoolingExpandedRowContainer = styled.div`
  ${commonStyles}
  grid-template-areas:
    '. measurementTarget completedTime limit'
    '. note completedBy peakTemperature'
    '. image . .';
`

export const AutomaticCoolingExpandedRow = ({item, unit}: any) => {
  return (
    <AutomaticCoolingExpandedRowContainer>
      <MeasurementTargetArea target={item.measurementTarget} />
      <NoteArea notes={item.notes} />
      <CompletedByArea completedBy={item.reportedActor} />
      <CompletedTimeDurationArea
        startTime={item.coolingStartedTime}
        endTime={item.coolingEndedTime}
        duration={item.coolingDuration}
        alarms={item.alarms}
      />
      <ResultTargetAreaForCooling min={item.maxValue} max={item.minValue} unit={unit} />
      <PeakTemperatureArea peak={item.cycleLog?.firstMeasurement} alarms={item.alarms} unit={unit} />
      <ImageBlock image={item.asset} />
    </AutomaticCoolingExpandedRowContainer>
  )
}

const CoolingExpandedRowContainer = styled.div`
  ${commonStyles}
  grid-template-areas:
    '. measurementTarget completedTime limit'
    '. note completedBy measurementMethod'
    '. image . .'
  ${deleteGridRow};
`

export const CoolingExpandedRow = ({item, unit, showDelete}: any) => {
  return (
    <CoolingExpandedRowContainer>
      <MeasurementTargetArea target={item.measurementTarget} />
      <NoteArea notes={item.notes} />
      <CompletedByArea completedBy={item.reportedActor} />
      <MeasurementMethodArea method={item.measurementMethod} />
      <CompletedTimeDurationArea
        startTime={item.coolingStartedTime}
        endTime={item.coolingEndedTime}
        duration={item.coolingDuration}
      />
      <ImageBlock image={item.asset} />
      <ResultTargetAreaForCooling min={item.maxValue} max={item.minValue} unit={unit} />
      {showDelete && <DeleteEvent deleteHandler={item.deleteHandler} />}
    </CoolingExpandedRowContainer>
  )
}

const ManualExpandedRowContainer = styled.div`
  ${commonStyles}
  grid-template-areas:
    '. measurementTarget completedTime limit'
    '. note completedBy .'
    '. image . .'
    ${deleteGridRow};
`

export const ManualExpandedRow = ({item, showDelete}: any) => {
  return (
    <ManualExpandedRowContainer>
      <MeasurementTargetArea target={item.measurementTarget} />
      <NoteArea notes={item.notes} />
      <CompletedByArea completedBy={item.reportedActor} />
      <CompletedTimeArea completedTime={item.taskCompletedTime} />
      <ImageBlock image={item.asset} />
      <ResultTargetAreaForMinMax min={item.minValue} max={item.maxValue} unit={item.unit} isTemperature={false} />
      {showDelete && <DeleteEvent deleteHandler={item.deleteHandler} />}
    </ManualExpandedRowContainer>
  )
}

const TodoExpandedRowContainer = styled.div`
  ${commonStyles}
  grid-template-areas:
    '. subtasks completedTime .'
    '. note completedBy .'
    '. image . .'
    ${deleteGridRow};
`

export const TodoExpandedRow = ({item, showDelete}: any) => {
  return (
    <TodoExpandedRowContainer>
      {item.subTasksWithActors && item.subTasksWithActors.length > 0 ? (
        <SubtasksAreaWithActors tasks={item.subTasksWithActors} />
      ) : (
        <SubtasksArea tasks={item.choice || item.doneSubtasks} />
      )}
      <NoteArea notes={item.notes} />
      <CompletedByArea completedBy={item.reportedActor} />
      <CompletedTimeArea completedTime={item.taskCompletedTime} />
      <ImageBlock image={item.asset} />
      {showDelete && <DeleteEvent deleteHandler={item.deleteHandler} />}
    </TodoExpandedRowContainer>
  )
}

const WasteWithReasonRowContainer = styled.div`
  ${commonStyles}
  grid-template-areas:
    '. measurementTarget completedTime limit'
    '. note completedBy reason'
    '. image . .'
    ${deleteGridRow};
`

export const WasteWithReasonExpandedRow = ({item, unit, showDelete}: any) => {
  return (
    <WasteWithReasonRowContainer>
      <MeasurementTargetArea target={item.measurementTarget} />
      <NoteArea notes={item.notes} />
      <CompletedByArea completedBy={item.reportedActor} />
      <CompletedTimeArea completedTime={item.taskCompletedTime} />
      <WasteReasonArea reason={item.reasonForWaste} />
      <ImageBlock image={item.asset} />
      <ResultTargetArea max={item.maxValue} unit={item.unit || unit} />
      {showDelete && <DeleteEvent deleteHandler={item.deleteHandler} />}
    </WasteWithReasonRowContainer>
  )
}

const WasteExpandedRowContainer = styled.div`
  ${commonStyles}
  grid-template-areas:
    '. measurementTarget completedTime limit'
    '. note completedBy .'
    '. image . .'
    ${deleteGridRow};
`

export const WasteExpandedRow = ({item, unit, showDelete}: any) => {
  return (
    <WasteExpandedRowContainer>
      <MeasurementTargetArea target={item.measurementTarget} />
      <NoteArea notes={item.notes} />
      <CompletedByArea completedBy={item.reportedActor} />
      <CompletedTimeArea completedTime={item.taskCompletedTime} />
      <ImageBlock image={item.asset} />
      <ResultTargetArea max={item.maxValue} unit={item.unit || unit} />
      {showDelete && <DeleteEvent deleteHandler={item.deleteHandler} />}
    </WasteExpandedRowContainer>
  )
}

const HygieneExpandedRowContainer = styled.div`
  ${commonStyles}
  grid-template-areas:
    '. measurementTarget completedTime limit'
    '. note completedBy measurementMethod'
    '. image . .'
    ${deleteGridRow};
`

export const HygieneExpandedRow = ({item, unit, showDelete}: any) => {
  return (
    <HygieneExpandedRowContainer>
      <MeasurementTargetArea target={item.measurementTarget} />
      <NoteArea notes={item.notes} />
      <CompletedByArea completedBy={item.reportedActor} />
      <MeasurementMethodArea method={item.measurementMethod} />
      <CompletedTimeArea completedTime={item.taskCompletedTime} />
      <ImageBlock image={item.asset} />
      <ResultTargetArea max={item.maxValue} unit={item.unit || unit} />
      {showDelete && <DeleteEvent deleteHandler={item.deleteHandler} />}
    </HygieneExpandedRowContainer>
  )
}

const TemperatureExpandedRowContainer = styled.div`
  ${commonStyles}
  grid-template-areas:
    '. measurementTarget completedTime limit'
    '. note completedBy measurementMethod'
    '. image . .'
    ${deleteGridRow};
`

export const TemperatureExpandedRow = ({item, unit, showDelete}: any) => {
  return (
    <TemperatureExpandedRowContainer>
      <MeasurementTargetArea target={item.measurementTarget} />
      <NoteArea notes={item.notes} />
      <CompletedByArea completedBy={item.reportedActor} />
      <MeasurementMethodArea method={item.measurementMethod} />
      <CompletedTimeArea completedTime={item.taskCompletedTime} />
      <ImageBlock image={item.asset} />
      <ResultTargetAreaForMinMax min={item.minValue} max={item.maxValue} unit={unit} isTemperature={true} />
      {showDelete && <DeleteEvent deleteHandler={item.deleteHandler} />}
    </TemperatureExpandedRowContainer>
  )
}

export const ErrorExpandedRow = () => (
  <Text size="S" color={colors.system.red}>
    {'Error: this tasktype layout is not defined'}
  </Text>
)
