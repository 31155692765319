import {AsyncAction} from 'overmind'
import {FilterOptions} from '../tasks/effects'
import {Alarm} from './state'
import {resolveAlarmIfFound} from './utils'

export const getAlarm: AsyncAction<{alarmId: string}, Alarm | null> = async ({state, effects}, params) => {
  const siteId = state.site?.id
  if (siteId) {
    const alarm = await effects.v1.alarms.alarmsApi.getAlarm(params.alarmId, siteId)
    return alarm
  } else {
    console.error('missing site id when fetching alarm id: ', params.alarmId)
    return null
  }
}

export const getAlarmsForSiteWithPagination: AsyncAction<any, any> = async (
  {state, effects},
  params: {siteId: string; options: FilterOptions; showLoadingIndicator: boolean}
) => {
  try {
    if (params.showLoadingIndicator) {
      state.v1.alarms.loading = true
    }
    const response = await effects.v1.alarms.alarmsApi.getAlarmsForSiteWithPagination(params.siteId, params.options)
    state.v1.alarms.filters = {
      alarmTypes: response.filters.alarmTypes,
      completedBy: response.filters.completedBy
    }
    state.v1.alarms.allAlarms = response.allAlarms
    state.v1.alarms.applianceAlarms = response.applianceAlarms
    state.v1.alarms.taskAlarms = response.taskAlarms
    state.v1.alarms.applianceAlarmsCount = response.applianceAlarmsCount
    state.v1.alarms.taskAlarmsCount = response.taskAlarmsCount
  } catch (err) {
    state.v1.alarms.error = err as Error
  } finally {
    if (params.showLoadingIndicator) {
      state.v1.alarms.loading = false
    }
  }
}

export const getMoreAlarmsForSite: AsyncAction<any, any> = async (
  {state, effects},
  {type, siteId, options}: {type: string; siteId: string; options: FilterOptions}
) => {
  try {
    state.v1.alarms.loadingMoreData = true

    const response = await effects.v1.alarms.alarmsApi.getMoreAlarmsForSite(siteId, type, options)
    switch (type) {
      case 'all':
        state.v1.alarms.allAlarms = [...state.v1.alarms.allAlarms, ...response.alarms]
        break
      case 'task':
        state.v1.alarms.taskAlarms = [...state.v1.alarms.taskAlarms, ...response.alarms]
        break
      case 'appliance':
        state.v1.alarms.applianceAlarms = [...state.v1.alarms.applianceAlarms, ...response.alarms]
        break
    }
  } catch (err) {
    state.v1.alarms.error = err as Error
  } finally {
    state.v1.alarms.loadingMoreData = false
  }
}

export const resolveAlarm: AsyncAction<any, any> = async (
  {state, effects},
  params: {alarmId: string; resolution: string; resolvedBy: string; siteId: string}
) => {
  const resolvedAlarm = await effects.v1.alarms.alarmsApi.resolveAlarm(
    params.alarmId,
    params.resolution,
    params.resolvedBy,
    params.siteId
  )

  // Any alarm that is resolved is two arrays, in
  // `allAlarms` and `taskAlarms` or `applianceAlarms`
  // depending on its category. Go through the whole
  // state and resolve all the copies of the alarm since
  // we don't know the category (task or appliance) at this point.
  resolveAlarmIfFound(state.v1.alarms.allAlarms, params.alarmId, resolvedAlarm)
  resolveAlarmIfFound(state.v1.alarms.applianceAlarms, params.alarmId, resolvedAlarm)
  resolveAlarmIfFound(state.v1.alarms.taskAlarms, params.alarmId, resolvedAlarm)
}
