import React from 'react'
import styled from 'styled-components'

import {FullScreenMainLayout} from '../../Layout/Layout'
import {useAppState} from '../../../state'
import {Helmet} from 'react-navi-helmet-async'
import QuickSightView from '../../Atoms/QuicksightView'

const DashboardContainer = styled.div`
  height: 100vh;
  width: 100%;
  overflow: hidden;
`
//Dashboard component that the production uses in the /quicksight url for development purposes
const TestQuicksightDashboardView = () => {
  return (
    <FullScreenMainLayout variant="white">
      <Helmet title={'Test Dashboard'} />
      <DashboardContainer>
        <QuickSightView isTestDashboard={true} />
      </DashboardContainer>
    </FullScreenMainLayout>
  )
}

export default () => {
  const {state} = useAppState()
  return !!state.me && !!state.currentUser ? <TestQuicksightDashboardView /> : null
}
