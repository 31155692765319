import React, {useEffect} from 'react'

import {useNavigation} from 'react-navi'
import {useAppState} from '../../../state'
import {useTranslation} from 'react-i18next'

import {MainLayoutWithoutStretch, MainContent, RightSidebar} from '../../Layout/Layout'
import {HeaderTitle, HeaderRow} from '../../Molecules/ViewComponents/ViewHeader'
import {Baseline, InvisibleContainer} from '../../Layout/Grid'
import ChainList from './ChainList'
import EditNewsTicker from './EditNewsTicker'
import {getSites} from '../../../config/utils'
import {Helmet} from 'react-navi-helmet-async'
import CreateChainButton from './CreateChainButton'

/**
 * TODO: handle loading and error states
 */
const AdminView = () => {
  const {t} = useTranslation('admin')
  const nav = useNavigation()
  const {state, effects, actions} = useAppState()
  const handleEdit = (id: string) => {
    nav.navigate('edit/chain/' + id)
  }
  useEffect(() => {
    actions.getChains()
  }, [effects])

  const onSelectChain = async (id: string) => {
    const sites = getSites(state.chainsById[id])

    if (sites.length > 0) {
      localStorage.setItem('selectedChainId', id)
      // §updateSelectedChainId()
      window.location.reload()
    } else {
    }
  }

  return (
    <MainLayoutWithoutStretch>
      <Helmet title={t('common:routes.admin', 'Admin')} />
      <MainContent variant="white" data-cy="chefstein-superuser-home-page">
        <HeaderRow description={t('admin:messages.viewDescription', 'Select which chain you want to view.')}>
          <HeaderTitle>{t('admin:labels.adminTitle', 'Fredman Chefstein')}</HeaderTitle>
          <CreateChainButton />
        </HeaderRow>
        {state.chains !== undefined && (
          <Baseline>
            <ChainList onSelectChain={onSelectChain} chains={state.chains} onConfigure={handleEdit} />
          </Baseline>
        )}
      </MainContent>
      <RightSidebar>
        <InvisibleContainer style={{marginTop: '11rem'}}>
          <EditNewsTicker />
        </InvisibleContainer>
      </RightSidebar>
    </MainLayoutWithoutStretch>
  )
}

export default () => {
  const {state} = useAppState()
  return !!state.me && !!state.currentUser ? <AdminView /> : null
}
