import React from 'react'
import {useAppState} from '../../state'
import {Text, Table, ColumnDef, SharedIcon} from '@fredman/chefstein-shared-frontend-components'
import {colors} from '../../sharedComponents/colors'

import {useTranslation} from 'react-i18next'
import {Alarm} from '../../state/alarms/state'
import {
  dateValueFormatter,
  alarmNameFormatter,
  Units,
  alarmTypeToTranslation,
  alarmSubtypeToTranslation,
  alarmValueFormatter,
  alarmUnit,
  alarmIconPicker,
  alarmStatus
} from './formatters'
import {ResolvedAlarmExpandedRow} from './resolvedAlarmExpandedRow'
import styled from 'styled-components'
import {TFunction} from 'i18next'
import {Site} from '../../state/state'
import {resolverNameFormatter} from './formatters'

// TODO: Promote to shared UI components
const TableDivider = styled.div`
  background-color: ${colors.system.grey_5};
  border-radius: 0.5rem;
  height: 2rem;
  padding: 0 1rem;
  line-height: 2rem;
`

interface AlarmsTableProps {
  locale: string
  alarms: Alarm[]
  onLoadMoreResolvedAlarms: () => any
  onResolveAlarm: (alarmId: string) => any
}

export const AlarmsTable = (props: AlarmsTableProps) => {
  const {state} = useAppState()
  const {t} = useTranslation(['alarms', 'appliances'])

  const {locale} = props

  const units: Units = {
    temperature: state.site!.temperatureUnit,
    weight: state.site!.weightUnit
  }

  const expansionPadder = {
    title: '',
    dataKeys: [],
    widthPercentage: '5%',
    component: () => <></>
  } as ColumnDef
  const activeAlarmsColumnDefs = [...tableColumnDefs(t, colors.system.red, units, locale, state.site!), expansionPadder]
  const resolvedAlarmsColumnDefs = tableColumnDefs(t, colors.brand.cobalt, units, locale, state.site!)

  const expandable = {
    expandedRowRender: (alarm: Alarm) => <ResolvedAlarmExpandedRow t={t} alarm={alarm} locale={locale} units={units} />
  }

  const resolvedAlarms = props.alarms.filter(a => a.status === 'resolved')
  const activeAlarms = props.alarms.filter(a => a.status === 'active')

  return (
    <div style={{height: '100%', marginTop: '-1px'}}>
      <div>
        <Table columnDefs={activeAlarmsColumnDefs} data={[]} />
      </div>
      <div style={{height: '100%', overflow: 'scroll'}}>
        <TableDivider>
          <Text size="S" strong>
            {`${t('alarms:label.table.sectionHeadingActive', 'Active')} (${activeAlarms.length})`}
          </Text>
        </TableDivider>
        <div>
          {activeAlarms.length === 0 ? (
            <div style={{padding: '1.5rem 0', display: 'flex', justifyContent: 'center'}}>
              <Text size="S">{t('alarms:labels.noActiveAlarms', 'No active alarm at the moment.')}</Text>
            </div>
          ) : (
            <Table
              columnDefs={activeAlarmsColumnDefs}
              data={activeAlarms}
              rowHoverBackgroundColor={colors.system.white}
              onClickRow={row => props.onResolveAlarm(row.id)}
              hideHeader
            />
          )}
        </div>
        <TableDivider>
          <Text size="S" strong>
            {`${t('alarms:label.table.sectionHeadingResolved', 'Resolved')} (${resolvedAlarms.length})`}
          </Text>
        </TableDivider>
        <div>
          <Table
            columnDefs={resolvedAlarmsColumnDefs}
            data={resolvedAlarms}
            rowHoverBackgroundColor={colors.system.white}
            expandable={expandable}
            rowExpandedBackgroundColor={colors.system.white}
            lastRow={props.onLoadMoreResolvedAlarms()}
            hideHeader
          />
        </div>
      </div>
    </div>
  )
}

const tableColumnDefs = (t: TFunction, textColor: string, units: Units, locale: string, site: Site): ColumnDef[] => {
  return [
    {
      title: '',
      dataKeys: ['type'],
      widthPercentage: '4rem',
      component: (props: any) => {
        const type = props.values[0]

        return (
          <div style={{paddingLeft: '1rem'}}>
            <SharedIcon icon={alarmIconPicker(type)} height="2em" width="2em" fill={textColor} />
          </div>
        )
      }
    },
    {
      title: t('alarms:labels.tableHeading.type', 'Alarm type'),
      dataKeys: ['type'],
      widthPercentage: '15%',
      component: (props: any) => {
        const type = props.values[0]

        return (
          <Text size="S" strong={props.selected} color={textColor}>
            {alarmTypeToTranslation(t, type)}
          </Text>
        )
      }
    },
    {
      title: t('alarms:labels.tableHeading.subtype', 'Alarm condition'),
      dataKeys: ['type', 'subtype'],
      widthPercentage: '20%',
      component: (props: any) => {
        const type = props.values[0]
        const subtype = props.values[1]
        const tableSubtype = subtype ?? undefined

        return (
          <Text size="S" strong={props.selected} color={textColor}>
            {alarmSubtypeToTranslation(t, type, tableSubtype)}
          </Text>
        )
      }
    },
    {
      title: t('alarms:labels.tableHeading.name', 'Name'),
      dataKeys: ['name', 'sensorRole'],
      widthPercentage: '30%',
      component: (props: any) => {
        const name = props.values[0]
        const sensorRole = props.values[1]

        return (
          <Text size="S" strong={props.selected} color={textColor}>
            {alarmNameFormatter(t, name, sensorRole)}
          </Text>
        )
      }
    },
    {
      title: t('alarms:labels.tableHeading.status', 'Status'),
      dataKeys: ['status', 'isResolvable', 'type'],
      widthPercentage: '10%',
      component: (props: any) => {
        const status = props.values[0]
        const isResolvable = props.values[1]
        const type = props.values[2]

        return (
          <Text size="S" strong={props.selected} color={textColor}>
            {alarmStatus(t, status, isResolvable, type)}
          </Text>
        )
      }
    },
    {
      title: t('alarms:labels.tableHeading.triggeredTime', 'Triggered time'),
      dataKeys: ['triggeredTime'],
      widthPercentage: '15%',
      component: (props: any) => {
        const triggeredTime = props.values[0]

        return (
          <Text size="S" strong={props.selected} color={textColor}>
            {dateValueFormatter(triggeredTime, locale)}
          </Text>
        )
      }
    },
    {
      title: t('alarms:labels.tableHeading.measuredValue', 'Measured value'),
      dataKeys: ['measuredValue', 'type', 'subtype', 'unit'],
      widthPercentage: '10%',
      component: (props: any) => {
        const measuredValue = props.values[0]
        const type = props.values[1]
        const subtype = props.values[2]
        const unit = props.values[3]

        return (
          <Text size="S" strong={props.selected} color={textColor}>
            {alarmValueFormatter(measuredValue, type, subtype, site)}
            {alarmUnit(t, type, subtype, unit, units)}
          </Text>
        )
      }
    },
    {
      title: t('alarms:labels.tableHeading.resolvedBy', 'Resolved by'),
      dataKeys: ['resolvedBy', 'actor', 'isResolvable', 'status'],
      widthPercentage: '12%',
      component: (props: any) => {
        if (props.values[3] === 'active') {
          const isResolvable = props.values[2]
          return (
            <Text size="S" strong={props.selected} color={colors.system.blue}>
              {!isResolvable ? t<string>('alarms:label.showAppliance', 'Show appliance') : ''}
            </Text>
          )
        } else {
          return (
            <Text size="S" strong={props.selected} color={textColor}>
              {props.values[0] || resolverNameFormatter(props.values[1])}
            </Text>
          )
        }
      }
    }
  ]
}
