import React, {useEffect, useState} from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import {
  Title,
  CardGrid,
  ApplianceCard,
  ApplianceDataCycleProgressType
} from '@fredman/chefstein-shared-frontend-components'
import {colors} from '../../sharedComponents/colors'

import {useAppState} from '../../state'
import {
  checkLorawanSensor,
  getApplianceData,
  getApplianceIcon,
  hasApplianceCoolingLowAlarm,
  isApplianceCoolingInProgress
} from '../appliances/appliancesUtils'
import {PaginationIndicator} from './InfoView'
import {ApplianceCoolingProgress} from '../appliances/ApplianceCoolingProgress'
import {Appliance, Sensor} from '../../state/appliances/state'
import {useTranslation} from 'react-i18next'

const AVERAGE_CARD_WIDTH_IN_PX = 196

interface AppliancesListProps {
  title?: string
  appliances: Appliance[]
  scale: number
  rows?: number
  width?: number
  paginationPosition: 'top' | 'bottom'
}

export const AppliancesList: React.FC<AppliancesListProps> = props => {
  const {appliances, title, scale, rows, width, paginationPosition} = props
  const {state} = useAppState()
  const {t} = useTranslation('appliances')

  const [currentPage, setCurrentPage] = useState(0)
  const [cardsArragement, setCardsArragement] = useState([0])

  const showEquipmentType = (equipment: Appliance, appliancesInCurrentPage: Appliance[], i: number) => {
    // only show equipment type name(e.g. coolers, dishwashers) for the first equipment of that type
    if (i === 0 || appliancesInCurrentPage[i - 1].type !== appliancesInCurrentPage[i].type) {
      return (
        <AppliancesTitle level={4}>{t(`appliances:labels.${equipment.type}s`, `${equipment.type}s`)}</AppliancesTitle>
      )
    } else {
      return <AppliancesTitle level={4} style={{minHeight: '1.75rem'}} />
    }
  }

  useEffect(() => {
    const sensorsCount = appliances.map(getApplianceSensorValueCount)

    const cardsArragement = getCardsArragement(sensorsCount, scale, rows, width)
    setCardsArragement(cardsArragement)

    const cardsCountInCurrentPage = sensorsCount
      .slice(cardsArragement[currentPage], cardsArragement[currentPage + 1])
      .reduce((a, b) => a + b, 0)
    // the time for showing each page depends on how many cards for this page
    const timeoutPerPage = Math.min(10000, 2000 + cardsCountInCurrentPage * 1 * 1000)
    console.debug('timeoutPerPage', timeoutPerPage)

    const changePage = () => {
      if (currentPage < cardsArragement.length - 2) {
        setCurrentPage(currentPage + 1)
      } else {
        setCurrentPage(0)
      }
    }
    const timer = setInterval(changePage, timeoutPerPage)

    return () => {
      console.debug('AutoCarousel: stop timer')
      clearInterval(timer)
    }
  }, [appliances, currentPage])

  const appliancesInCurrentPage = appliances.slice(cardsArragement[currentPage], cardsArragement[currentPage + 1])

  const showIndicator = () => {
    const page = cardsArragement.length - 1
    if (page > 1) {
      return _.times(page).map(v => <span key={v}></span>)
    }
  }

  return (
    <AppliancesListContainer>
      <TitleContainer>
        {title && (
          <>
            <Title
              level={4}
              onClick={event => {
                event.stopPropagation()
              }}
              strong
            >
              {`${title} (${appliances.length})`}
            </Title>
            <Divider />
          </>
        )}
        {paginationPosition === 'top' && (
          <PaginationIndicator activePage={currentPage + 1}>{showIndicator()}</PaginationIndicator>
        )}
      </TitleContainer>
      <Content>
        <CardGridContainer>
          <CardGrid compact style={{gap: '1rem'}}>
            {appliancesInCurrentPage.map((appliance, i) => {
              let data = undefined
              let tagLabel = undefined
              if (isApplianceCoolingInProgress(appliance)) {
                data = [
                  <ApplianceCoolingProgress
                    appliance={appliance}
                    cycleProgressType={ApplianceDataCycleProgressType.small}
                    hasAlarm={hasApplianceCoolingLowAlarm(appliance)}
                  />
                ]
                tagLabel = t('appliances:labels.cooling', 'Cooling').toUpperCase()
              } else {
                data = getApplianceData(t, appliance, state.site!)
              }
              return (
                <div style={{gridColumn: `auto / span ${data.length > 1 ? 2 : 1}`}}>
                  {showEquipmentType(appliance, appliancesInCurrentPage, i)}
                  <ApplianceCard
                    key={appliance.id}
                    icon={getApplianceIcon(appliance.type)}
                    data={data}
                    name={appliance.name}
                    disabled={appliance.suspended}
                    activeAlarmCount={appliance.unresolvedAlarmCount}
                    tagLabel={tagLabel}
                    compact
                  />
                </div>
              )
            })}
          </CardGrid>
        </CardGridContainer>
      </Content>
      {paginationPosition === 'bottom' && (
        <PaginationIndicator activePage={currentPage + 1} style={{marginTop: '1rem'}}>
          {showIndicator()}
        </PaginationIndicator>
      )}
    </AppliancesListContainer>
  )
}

const getMaxCardsInScreen = (scale = 1, rows = 1, width = window.innerWidth) => {
  /*
   ** In th worst case with 3 rows and cards taking 1 or 2 card slots we may lose
   ** 3 card slots for screen if cards aren't filling row fully. The widest card takes
   ** 2 slots so we can use it as a buffer with the last row.
   */
  const CARD_BUFFER = 2

  const scaledCardWidth = AVERAGE_CARD_WIDTH_IN_PX * scale
  const maxCards = Math.floor((rows * width) / scaledCardWidth)
  return maxCards > 6 ? maxCards - CARD_BUFFER : maxCards
}

const getCardsArragement = (sensorsCount: number[], scale = 1, rows = 1, width = window.innerWidth) => {
  const maxCardsInScreen = getMaxCardsInScreen(scale, rows, width)
  let capacityPerPage = maxCardsInScreen
  let cardsArragement = [0]

  for (const [i, v] of sensorsCount.entries()) {
    capacityPerPage = capacityPerPage - v
    if (i === sensorsCount.length - 1) {
      cardsArragement = [...cardsArragement, i + 1]
    } else if (capacityPerPage === 0) {
      cardsArragement = [...cardsArragement, i + 1]
      capacityPerPage = maxCardsInScreen
    } else if (capacityPerPage < 0) {
      cardsArragement = [...cardsArragement, i]
      capacityPerPage = maxCardsInScreen - v
    }
  }
  return cardsArragement
}

const getSensorValueCount = (sensor?: Sensor): number => (checkLorawanSensor(sensor) ? 2 : 1)

const getApplianceSensorValueCount = (appliance: Appliance): number =>
  appliance.sensors?.reduce((res, sensor) => res + getSensorValueCount(sensor), 0) ?? 0

export const getOrderedAppliances = (appliances: Appliance[]) => {
  return _.chain(appliances)
    .filter(appliance => !appliance.suspended)
    .orderBy(appliance => appliance.type)
    .sort((a, b) => getApplianceSensorValueCount(a) - getApplianceSensorValueCount(b)) // small cards with one sensor go first
    .value()
}

const TitleContainer = styled.div`
  padding-bottom: 0.75rem;
  display: grid;
  grid-template-columns: 1fr 3fr auto;
  align-items: center;
  justify-content: space-between;
`

const Content = styled.div`
  display: flex;
  align-items: flex-start;
`

const CardGridContainer = styled.div`
  width: 100%;
`

const Divider = styled.hr`
  margin: 0;
  height: 1px;
  background-color: ${colors.system.grey_5};
  border: none;
`
const AppliancesListContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const AppliancesTitle = styled(Title)`
  min-height: 1.75rem;
`
