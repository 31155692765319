import React, {useEffect, useCallback, memo} from 'react'
import {useTranslation} from 'react-i18next'
import {H3, P} from '../Atoms/Typography'
import {useNavigation} from 'react-navi'
import {Baseline, Row} from '../Layout/Grid'
import {useAppState} from '../../state'
import {MainLayoutWithoutStretch, MainContent} from '../Layout/Layout'
import {A11yAccordion} from '../Atoms/Accordion'
import {HeaderRow, GoBackHeaderTitle, HeaderActions} from '../Molecules/ViewComponents/ViewHeader'
import {Loader} from '../../Routes'
import {EmptyTaskList} from './Home/NoContent'
import {A11yListContainer, A11yIconListItem} from '../Atoms/Lists'
import i18n from 'i18next'
import {Helmet} from 'react-navi-helmet-async'
import {taskGroupNameArgs} from '../../config/utils'
import {ErrorMessage} from '../Molecules/Errors/ErrorMessage'
import {TaskSettings} from '../../state/settings/tasks/state'
import {SharedIcon} from '@fredman/chefstein-shared-frontend-components'
import {taskGroupIconPicker} from '../../pages/tasks/utils'

// (SiteName | SharedTasks) -> TaskGroups
interface SettingsBlockProps {
  blockName: string
  blockData: SettingsSubBlockProps
}

// TaskGroup -> Tasks
interface SettingsSubBlockProps {
  [subBlockName: string]: SettingsRowProps[]
}

interface SettingsRowProps {
  id: string
  name: string
  icon: string
  siteExamples: string[]
  siteAmount: number
  target: string
  type: string
}

// Tasksettings siteExamples is a subset of all sites related to a task
const SITE_EXAMPLES_AMOUNT_MAX = 2

const EditTasks = memo(() => {
  const {state, actions} = useAppState()
  const nav = useNavigation()
  const {t} = useTranslation(['tasks', 'settings'])

  const setup = useCallback(async () => {
    actions.v1.settings.tasks.getTaskSettings()
  }, [actions.v1.settings])

  useEffect(() => {
    setup()
  }, [setup])

  const {data, pending: isLoading, error} = state.v1.settings.tasks
  const {sharedTasks, ...tasksBySite} = data

  const getSitesSortedByName = (tasksBySite: TaskSettings) => {
    return Object.entries(tasksBySite).sort(([a, _], [b, __]) => (a > b ? 1 : -1))
  }

  return (
    <MainLayoutWithoutStretch>
      <Helmet title={t('common:routes.taskSettings', 'Task settings')} />
      <Loader show={isLoading} />
      {!isLoading && (
        <MainContent variant="white">
          <Baseline>
            <HeaderRow
              dataCy="settings-edit-tasks-list"
              description={t(
                'tasks:messages.createEditTasks',
                'Create new tasks and edit details of existing tasks. In the list, shared tasks displays the tasks which are used in more than one site. View the complete list of a site by selecting the site on the list. To edit task groups and task order, select the setting icon above.'
              )}
            >
              <GoBackHeaderTitle
                label={t('tasks:labels.taskSettings', 'Tasks settings')}
                path={'/settings'}
                backLabel={t('settings:labels.settingsTitle', 'Settings')}
              />
              <HeaderActions
                onConfigure={() => nav.navigate('/edit/taskGroups')}
                onAdd={() => nav.navigate('/create/task')}
              />
            </HeaderRow>
            {error === null ? (
              <A11yListContainer>
                {!!sharedTasks && (
                  <SettingsBlock
                    key={'sharedTasks'}
                    blockName={t('tasks:labels.sharedTasks', 'Shared tasks')}
                    blockData={sharedTasks}
                  />
                )}
                {Object.keys(tasksBySite).length ? (
                  getSitesSortedByName(tasksBySite).map(([siteName, siteTasks]) => (
                    <SettingsBlock key={siteName} blockName={siteName} blockData={siteTasks} />
                  ))
                ) : (
                  <EmptyTaskList />
                )}
              </A11yListContainer>
            ) : (
              <ErrorMessage message={'We could not fetch the settings'} />
            )}
          </Baseline>
        </MainContent>
      )}
    </MainLayoutWithoutStretch>
  )
})

const SettingsBlock = ({blockName, blockData}: SettingsBlockProps) => {
  const {t} = useTranslation(['tasks', 'settings'])

  const getTaskGroupsSortedByTranslatedName = (blockData: SettingsSubBlockProps) => {
    return Object.keys(blockData).sort((a, b) => (t(...taskGroupNameArgs(a)) > t(...taskGroupNameArgs(b)) ? 1 : -1))
  }

  return (
    <A11yAccordion offset={2} title={blockName} storeId={blockName} openByDefault={false}>
      {getTaskGroupsSortedByTranslatedName(blockData).map(taskGroup => {
        return (
          <A11yAccordion
            offset={4}
            disableSelectedBorder
            storeId={`${blockName}:${taskGroup}`}
            title={t(...taskGroupNameArgs(taskGroup))}
            key={taskGroup}
            openByDefault={false}
          >
            {blockData[taskGroup].map(task => {
              return (
                <SettingsRow
                  key={task.id}
                  id={task.id}
                  name={task.name}
                  icon={task.icon}
                  siteExamples={task.siteExamples}
                  siteAmount={task.siteAmount}
                  type={task.type}
                  target={task.target}
                />
              )
            })}
          </A11yAccordion>
        )
      })}
    </A11yAccordion>
  )
}

const SettingsRow = (task: SettingsRowProps) => {
  const nav = useNavigation()
  const handleClick = (id: string, taskType: string) => {
    nav.navigate(`/edit/task/${taskType}/${id}`)
  }

  return (
    <A11yIconListItem
      offset={5}
      key={task.id}
      icon={<SharedIcon icon={taskGroupIconPicker(task.icon)} />}
      onClick={() => {
        handleClick(task.id, task.type)
      }}
      content={
        <Row justify="space-between">
          <H3>
            {task.name} {task.target ? ' - ' + task.target : null}
          </H3>
          {task.siteExamples.length > 1 && <P variant="small">{getSiteNames(task.siteExamples, task.siteAmount)}</P>}
        </Row>
      }
    />
  )
}

const getSiteNames = (sites: string[], amount: number) => {
  if (amount <= SITE_EXAMPLES_AMOUNT_MAX) {
    return sites.join(', ')
  } else if (amount > SITE_EXAMPLES_AMOUNT_MAX) {
    return `${sites[0]}, ${amount - 1} ${i18n.t('common:labels.othersAmount', 'others')}`
  }
  return i18n.t('tasks:labels.noSites', 'No sites')
}

export default EditTasks
