import React, {useState, useEffect} from 'react'
import {MainLayoutWithoutStretch, MainContent, NarrowRightSidebar} from '../../Layout/Layout'
import {HeaderRow, GoBackHeaderTitle} from '../../Molecules/ViewComponents/ViewHeader'
import {Baseline, Grid, InvisibleContainer} from '../../Layout/Grid'
import {BigLabelInput, LabelInput, Select} from '../../Atoms/Forms'
import {useNavigation, useCurrentRoute} from 'react-navi'
import {useAppState} from '../../../state'
import {DevicesForSite, GatewayWithSensors, SensorMetadataResponse, UserLanguage} from '../../../state/rest'
import {useTranslation} from 'react-i18next'
import {Control, useFieldArray, useForm} from 'react-hook-form'
import {Site} from '../../../state/state'
import {SiteSuspendedPeriods} from '../../../pages/settings'
import * as mz from 'moment-timezone'
import DeleteSensorModal from '../../Molecules/DeleteSensorModal'
import {UL, LI, P, Text, TitleH4, Text2} from '../../Atoms/Typography'
import {Button, ButtonRowWrap, ButtonRowGrid} from '../../Atoms/Buttons'
import {normalizeLocations, normalizeSites} from '../../../config/utils'
import SiteOrganisationList from '../../Organisms/SiteOrganisationList'
import {IconDelete} from '../../../Assets/Icons/TinyIcons'
import {Helmet} from 'react-navi-helmet-async'
import styled, {css} from 'styled-components'
import {IconAddItem} from '../../../Assets/Icons/LargeIcons'
import {colors} from '../../../sharedComponents/colors'
import {isEmpty} from 'lodash'
import {getDatetimeAgo} from '../../Atoms/Utils'
import {parseISO} from 'date-fns'
import _ from 'lodash'
import {DefaultTagsWidget} from '../../Organisms/Tags/DefaultSuggestionWidget'

/*
A list of locales copy-pasted from SO: https://stackoverflow.com/a/3191729
The original list has been sanitized a bit in order to make it work:
  - removed all three part locales such as 'bs-Cyrl-BA', 'ha-Latn-NG'
  - removed all the locales for which the Intl.DisplayNames didn't have a match for  such as 'en-029', 'en-cb'. 'az-az'
  - Deduplicate locales that match to the same country such as fi-FI & sv-Fi and so on
*/
const allLocales = [
  'am-ET',
  'ar-AE',
  'ar-BH',
  'ar-DZ',
  'ar-EG',
  'ar-IQ',
  'ar-JO',
  'ar-KW',
  'ar-LB',
  'ar-LY',
  'ar-MA',
  'ar-OM',
  'ar-QA',
  'ar-SA',
  'ar-SD',
  'ar-TN',
  'ar-YE',
  'be-BY',
  'bg-BG',
  'bn-BD',
  'cs-CZ',
  'da-DK',
  'de-AT',
  'de-DE',
  'de-LI',
  'dv-MV',
  'el-CY',
  'el-GR',
  'en-AU',
  'en-BZ',
  'en-CA',
  'en-GB',
  'en-IE',
  'en-IN',
  'en-JM',
  'en-MT',
  'en-MY',
  'en-NZ',
  'en-PH',
  'en-SG',
  'en-TT',
  'en-US',
  'en-ZA',
  'en-ZW',
  'es-AR',
  'es-BO',
  'es-CL',
  'es-CO',
  'es-CR',
  'es-DO',
  'es-EC',
  'es-ES',
  'es-GT',
  'es-HN',
  'es-MX',
  'es-NI',
  'es-PA',
  'es-PE',
  'es-PR',
  'es-PY',
  'es-SV',
  'es-UY',
  'es-VE',
  'et-EE',
  'fa-IR',
  'fi-FI',
  'fo-FO',
  'fr-FR',
  'fr-LU',
  'fr-MC',
  'he-IL',
  'hr-BA',
  'hr-HR',
  'hu-HU',
  'hy-AM',
  'id-ID',
  'ig-NG',
  'is-IS',
  'it-IT',
  'ja-JP',
  'ka-GE',
  'kk-KZ',
  'kl-GL',
  'km-KH',
  'ko-KR',
  'ky-KG',
  'lo-LA',
  'lt-LT',
  'lv-LV',
  'mk-MK',
  'mn-MN',
  'ms-BN',
  'nb-NO',
  'ne-NP',
  'nl-BE',
  'nl-NL',
  'pl-PL',
  'ps-AF',
  'pt-BR',
  'pt-PT',
  'rm-CH',
  'ro-RO',
  'ru-RU',
  'rw-RW',
  'si-LK',
  'sk-SK',
  'sl-SI',
  'sq-AL',
  'sr-ME',
  'sr-RS',
  'sv-SE',
  'sw-KE',
  'syr-SY',
  'th-TH',
  'tk-TM',
  'tr-TR',
  'uk-UA',
  'ur-PK',
  'vi-VN',
  'wo-SN',
  'zh-CN',
  'zh-HK',
  'zh-MO',
  'zh-TW'
]

interface SiteForm {
  address?: string
  city?: string
  contactEmail?: string
  contactName?: string
  contactPhone?: string
  internalId?: string
  country?: string
  description?: string
  postalCode?: string
  state?: string
  locale: string //  "fi-FI"
  name: string //  "Kamppi"
  temperatureUnit: string //  "C"
  timeZone: string //  "Europe/Helsinki"
  weightUnit: string //  "G"
  customerAmount?: number
  actorSuggestions?: string[]
}

const getRealValues = (l: string) => {
  if (l === 'en-US') {
    return {
      weight: 'LB',
      temperature: 'F'
    }
  } else
    return {
      weight: 'KG',
      temperature: 'C'
    }
}

const Column = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1000px;
`

const TabContainer = styled.ul`
  display: flex;
  list-style-type: none;
  padding-bottom: 1rem;
`
const TabItem = styled.li<{isSelected: boolean}>`
  margin-right: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.7rem;
  text-align: center;
  cursor: pointer;

  ${props =>
    props.isSelected &&
    css`
      font-weight: bold;
      border-bottom: 2px solid #2e3b47;
    `}
`

const SubmitErrorMessage = styled.p`
  color: ${colors.system.red};
`

enum TabQueryParam {
  SiteDetails = 'site-details',
  GatewaysAndSensors = 'gateways-and-sensors',
  SuspendedPeriods = 'suspended-periods'
}

interface Tab {
  name: string
  element: JSX.Element
  tab: TabQueryParam
}

const EditSite = ({saved}: {saved?: Site}) => {
  const route = useCurrentRoute()
  const navigation = useNavigation()
  const {state} = useAppState()
  const isSuperUser = state.me?.accessRights.superuser === true
  const {t} = useTranslation('locations')
  const getTab = (allTabs: Tab[]) => {
    if (
      route.url.query?.tab === TabQueryParam.GatewaysAndSensors &&
      allTabs.find(tab => tab.tab === TabQueryParam.GatewaysAndSensors)
    ) {
      return TabQueryParam.GatewaysAndSensors
    } else {
      return route.url.query?.tab === TabQueryParam.SuspendedPeriods
        ? TabQueryParam.SuspendedPeriods
        : TabQueryParam.SiteDetails
    }
  }
  const getGatewaysAndSensors = (devices: DevicesForSite[]) => {
    if (saved) {
      const site = devices.find(s => s.siteId === saved.id)
      if (site) {
        return site.devices
      } else {
        return []
      }
    } else {
      return []
    }
  }

  const isEditing = false
  const siteDetailsTab = {
    name: t('common:locations.tabName.siteDetails', 'Site details'),
    element: <SiteDetails saved={saved} />,
    tab: TabQueryParam.SiteDetails
  }
  const gatewaysAndSensorsTab = {
    name: t('common:locations.tabName.gatewaysAndSensors', 'Gateways and sensors'),
    element: (
      <GatewayAndSensors
        site={saved!}
        gatewayData={getGatewaysAndSensors(state.gatewaysWithSensorsForSites)}
        sensorModels={state.sensorModels}
      />
    ),
    tab: TabQueryParam.GatewaysAndSensors
  }
  const suspendedPeriodsTab = {
    name: t('common:locations.tabName.suspendedPeriods', 'Site closing'),
    element: <SiteSuspendedPeriods site={saved!} />,
    tab: TabQueryParam.SuspendedPeriods
  }

  const tabs: Tab[] = [siteDetailsTab, suspendedPeriodsTab]
  if (isSuperUser) {
    tabs.push(gatewaysAndSensorsTab)
  }
  const [currentTab, setCurrentTab] = useState<string>(getTab(tabs))
  useEffect(() => {
    setCurrentTab(getTab(tabs))
  }, [route.url])

  const getTabElement = (tabs: Tab[]) => {
    return tabs.find(t => t.tab === currentTab)?.element
  }

  return (
    <MainLayoutWithoutStretch>
      {isEditing ? (
        <Helmet title={t('common:routes.editSite', 'Edit site')} />
      ) : (
        <Helmet title={t('common:routes.createSite', 'Create site')} />
      )}
      <MainContent variant="white">
        <HeaderRow>
          <GoBackHeaderTitle
            label={
              isEditing
                ? `${t('locations:labels.editSite', 'Edit site')}`
                : `${t('locations:labels.newSite', 'New site')}`
            }
            backLabel={t('locations:labels.locations', 'Location settings')}
          />
        </HeaderRow>
        <InvisibleContainer>
          <TabContainer>
            {tabs.map(t => (
              <TabItem
                isSelected={currentTab === t.tab}
                onClick={event => {
                  event.preventDefault()
                  navigation.navigate(`${route.url.pathname}?tab=${t.tab}`)
                }}
                key={t.tab}
                data-cy={`${t.tab}-tab`}
              >
                {t.name}
              </TabItem>
            ))}
          </TabContainer>
          <Baseline>{getTabElement(tabs)}</Baseline>
        </InvisibleContainer>
      </MainContent>
      <NarrowRightSidebar>
        <InvisibleContainer style={{marginTop: '4rem'}}>
          <Baseline margin="1rem">
            <Text level={3}>{t('locations:labels.organisationHierarchy', 'Organisation hierarchy')}</Text>
            <div>
              <SiteOrganisationList data={state.chainsById[state.selectedChainId!]} />
            </div>
          </Baseline>
        </InvisibleContainer>
      </NarrowRightSidebar>
    </MainLayoutWithoutStretch>
  )
}

const getTz = (locale: string): string[] => {
  const countryCode = locale.split('-')[1]
  const zonesWithOffset = mz.tz.zonesForCountry(countryCode, true)
  return _.sortBy(zonesWithOffset, o => o.offset).map(tz => tz.name)
}

const formatTimeZoneOption = (tz: string): string => {
  return `${tz} (UTC${mz.tz(tz).format('Z')})`
}

const SiteDetails = ({saved}: {saved?: Site}) => {
  const nav = useNavigation()
  const {state, actions, effects} = useAppState()
  const isSuperUser = state.me?.accessRights.superuser === true
  const {t} = useTranslation(['common', 'locations', 'settings'])
  const {register, watch, getValues, errors, setValue, formState} = useForm<SiteForm>({
    defaultValues: saved ? {...saved} : undefined,
    mode: 'onChange'
  })
  const [error, setError] = useState('')

  const [taskGeneratorError, setTaskGeneratorError] = useState(false)
  const isEditing = !!saved
  const locale = watch('locale')

  const timeZones = locale ? getTz(locale) : undefined
  const locations = normalizeLocations(state.chainsById[state.selectedChainId!])
  const savedLocation = locations.find(l => l.sites.find(s => s.id === saved?.id))
  const [actorSuggestions, setActorSuggestions] = useState(saved ? saved.actorSuggestions : [])

  const update = async () => {
    if (!!saved && !!locale) {
      try {
        const newSite = await effects.organisationApi.updateSite({
          ...getValues(),
          id: saved.id,
          locale: locale,
          suspendedPeriods: saved.suspendedPeriods,
          actorSuggestions
        })
        if (state.site?.id === newSite.data.id) {
          await actions.updateSite(newSite.data)
        }
        await actions.updateMe()
        if (state.me?.accessRights.superuser) {
          await actions.getChains()
        }
        nav.navigate(`/settings/locations/`)
      } catch (error) {}
    }
  }

  const generateTasks = async (language: string) => {
    const generatorSite = saved?.id
    if (!generatorSite) {
      setTaskGeneratorError(true)
    } else {
      const error = state.v1.taskTemplates.error
      const template = await actions.v1.taskTemplates.getTaskTemplates({siteIds: generatorSite, language})
      const result = !error && template ? await actions.v1.taskTemplates.generateTasksFromTemplate({template}) : null
      if (!error && result) {
        nav.navigate(`/settings/locations/`)
      } else {
        setTaskGeneratorError(true)
      }
    }
  }
  const cancel = () => nav.goBack()
  const disabled = !formState.isValid

  useEffect(() => {
    if (timeZones && timeZones.length === 1) {
      setValue('timeZone', timeZones[0])
    }
  }, [locale])

  const submit = async () => {
    try {
      await effects.organisationApi.createSite({
        location: {id: watch('location') as string},
        site: {
          ...getValues(),
          locale: locale,
          temperatureUnit: getRealValues(locale!).temperature,
          weightUnit: getRealValues(locale!).weight,
          actorSuggestions
        }
      })
      await actions.updateMe()
      if (state.me?.accessRights.superuser) {
        await actions.getChains()
      }
      nav.navigate(`/settings/locations/`)
    } catch (e) {}
  }

  const validateInput = (value: string) => {
    if (parseInt(value) > 0) {
      setValue(value)
      setError('')
    } else if (isEmpty(value)) {
      setValue('1')
    } else if (parseFloat(value) <= 0) {
      setError(`${t('locations:errors.notNegative', 'Value cannot be a negative number or a zero')}`)
      setValue('1')
    }
  }
  const userLanguage = state.me?.user.language || UserLanguage.en
  // The `DisplayNames` is supported by modern browsers but our Typescript
  // doesn't have a clue about it hence ignore the error
  // @ts-ignore
  const displayName = new Intl.DisplayNames([userLanguage], {type: 'region'})

  const [allCountryOptions, _setAllCountryOptions] = useState(
    allLocales
      .map(locale => ({
        option: displayName.of(locale.split('-')[1]),
        id: locale
      }))
      .sort((lhs, rhs) => {
        const lhsOption = lhs.option || ''
        const rhsOption = rhs.option || ''
        if (lhsOption < rhsOption) return -1
        if (lhsOption > rhsOption) return 1
        else return 0
      })
  )

  const infoBlocks = () => (
    <Baseline>
      <DefaultTagsWidget
        title={t('tasks:labels.actorSuggestions', 'Default suggestions for actors')}
        onChange={items => setActorSuggestions(items)}
        savedItems={saved ? saved.actorSuggestions : undefined}
      />
    </Baseline>
  )

  return (
    <>
      <Grid gap="2rem">
        <BigLabelInput
          required
          ref={register({required: t('common:validation.requiredField', 'Required field') as string})}
          name="name"
          labelText={t('locations:labels.siteName', 'Site name')}
          placeholder={t('locations:placeholders.siteName', 'Site name')}
          errorMsg={errors.name?.message}
        />
        <Grid gap="1rem">
          <LabelInput name="address" labelText={t('locations:labels.address', 'Address')} ref={register} />
          <LabelInput name="city" labelText={t('locations:labels.city', 'City')} ref={register} />
          <LabelInput name="state" labelText={t('locations:labels.state', 'State')} ref={register} />
          <LabelInput name="country" labelText={t('locations:labels.country', 'Country')} ref={register} />
        </Grid>
        <Baseline>
          <LabelInput
            name="description"
            labelText={t('locations:labels.description', 'Description of operation')}
            ref={register}
          />
          <LabelInput
            name="fixedCustomerAmount"
            labelText={t('common:general.customer', 'Fixed customer count')}
            ref={register}
            onChange={e => validateInput(e.target.value)}
            errorMsg={error}
            type="number"
          />
          <Select
            required
            returnId={true}
            label={t('locations:labels.location', 'Location')}
            options={locations.map(l => ({option: l.name, id: l.id}))}
            emptyStr={t('locations:labels.chooseLocation', 'Choose location')}
            ref={register({required: true})}
            nativeProps={{name: 'location', defaultValue: savedLocation?.id || '', disabled: !isSuperUser}}
            id="location"
          />
          <P variant="small">
            {!isSuperUser &&
              t(
                'locations:messages.locationChoisePermanent',
                'To change the organizational linkages please contact Fredman customer support'
              )}
          </P>
        </Baseline>
        <Baseline>
          <LabelInput
            name="contactName"
            labelText={t('locations:labels.contactPersonName', 'Contact person name')}
            ref={register}
          />
          <LabelInput
            name="contactEmail"
            labelText={t('common:general.email', 'Email')}
            ref={register({
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/i,
                message: t('common:validation.emailInvalid', 'Invalid email address')
              }
            })}
            type="email"
            errorMsg={errors.contactEmail?.message}
          />
          <LabelInput
            name="contactPhone"
            labelText={t('common:general.phoneNumber', 'Phone number')}
            ref={register({
              pattern: {
                message: t('common:validation.phoneNumberInvalid', 'Invalid phone number'),
                value: new RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g)
              }
            })}
            type="tel"
            errorMsg={errors.contactPhone?.message}
          />
          <LabelInput
            name="internalId"
            labelText={t('locations:labels.internalId', 'Site internal ID')}
            ref={register}
          />
        </Baseline>
        <div className="empty"></div>
        <Baseline>
          <Select
            required
            returnId={true}
            nativeProps={{defaultValue: 'fi-FI'}}
            emptyStr={t('locations:labels.selectRegion', 'Select region')}
            ref={register}
            id="locale"
            label={t('locations:labels.region', 'Region')}
            options={allCountryOptions}
          />
          {!!locale ? (
            <UL style={{fontSize: 14}}>
              <LI>
                {`${t('locations:labels.dateAndTimeFormat', 'Date and time format')}: ${new Date().toLocaleDateString(
                  getValues().locale
                )} ${new Date().toLocaleTimeString(getValues().locale)}`}
              </LI>
            </UL>
          ) : null}
          {!!timeZones ? (
            timeZones.length > 1 ? (
              <Select
                required
                nativeProps={{defaultValue: saved ? saved.timeZone : ''}}
                emptyStr={t('locations:labels.selectTimeZone', 'Select a time zone')}
                ref={register}
                id="timeZone"
                returnId={true}
                label={t('locations:labels.timeZone', 'Time zone')}
                options={timeZones.map(tz => ({
                  option: formatTimeZoneOption(tz),
                  id: tz
                }))}
              />
            ) : (
              <Select
                required
                nativeProps={{defaultValue: saved ? saved.timeZone : timeZones[0]}}
                ref={register}
                id="timeZone"
                returnId={true}
                label={t('locations:labels.timeZone', 'Time zone')}
                options={timeZones.map(tz => ({
                  option: formatTimeZoneOption(tz),
                  id: tz
                }))}
              />
            )
          ) : null}
          <Select
            required
            returnId={true}
            nativeProps={{defaultValue: 'KG'}}
            emptyStr={t('locations:labels.weight', 'Weight unit ')}
            ref={register}
            id="weightUnit"
            label={t('locations:labels.weight', 'Weight unit')}
            options={[
              {option: t('common:weightUnits.long.kilogram', 'Kilogram, kg'), id: 'KG'},
              {option: t('common:weightUnits.long.pounds', 'Pound, lbs'), id: 'LB'}
            ]}
          />
          <Select
            required
            returnId={true}
            nativeProps={{defaultValue: 'C'}}
            emptyStr={t('locations:labels.temperatureFormat', 'Temperature unit ')}
            ref={register}
            id="temperatureUnit"
            label={t('locations:labels.temperatureFormat', 'Temperature unit')}
            options={[
              {option: t('common:temperatureFormat.celsius', 'Celsius'), id: 'C'},
              {option: t('common:temperatureFormat.fahrenheit', 'Fahrenheit'), id: 'F'}
            ]}
          />
        </Baseline>
        {infoBlocks()}
        {isSuperUser ? (
          <Baseline>
            <TaskGeneratorTitle>{t('settings:taskGenerator.title', 'Generate set of tasks')}</TaskGeneratorTitle>
            <Text2>
              {t(
                'settings:taskGenerator.description',
                'Click the button if you want to create a ready made set of tasks for this site.'
              )}
            </Text2>
            {taskGeneratorError ? (
              <TaskGeneratorErrorMessage>
                {t('settings:error.taskGenerator', 'Something went wrong - contact the support')}
              </TaskGeneratorErrorMessage>
            ) : null}
            <Button
              type="button"
              variant="secondary"
              onClick={() => generateTasks('finnish')}
              disabled={!Boolean(saved?.id)}
            >
              {t('common:buttons.generateTasksFinnish', 'Generate tasks in Finnish')}
            </Button>
            <Button
              type="button"
              variant="secondary"
              onClick={() => generateTasks('english')}
              disabled={!Boolean(saved?.id)}
            >
              {t('common:buttons.generateTasksEnglish', 'Generate tasks in English')}
            </Button>
            <Button
              type="button"
              variant="secondary"
              onClick={() => generateTasks('swedish')}
              disabled={!Boolean(saved?.id)}
            >
              {t('common:buttons.generateTasksSwedish', 'Generate tasks in Swedish')}
            </Button>
          </Baseline>
        ) : null}
      </Grid>
      <ButtonRowWrap>
        <div className="empty"></div>
        <ButtonRowGrid>
          <Button name="cancel-button" negative variant="secondary" onClick={cancel}>
            {t('common:buttons.cancel', 'Cancel')}
          </Button>
          <Button
            type="submit"
            disabled={disabled}
            onClick={() => (isEditing ? update() : submit())}
            variant="secondary"
          >
            {t('common:buttons.save', 'Save')}
          </Button>
        </ButtonRowGrid>
      </ButtonRowWrap>
    </>
  )
}

const TaskGeneratorTitle = styled(TitleH4)`
  font-weight: 900;
`

const TaskGeneratorErrorMessage = styled.div.attrs({
  className: 'error'
})`
  color: ${colors.system.red};
`

const NOKEVAL_GATEWAY_ID_REGEX = /^(A|N)\d{6}$/i
const LORAWAN_EUI_REGEX = /^[a-f0-9]{16}$/i
const GATEWAY_REGEX = /(?:^(A|N)\d{6}$|^[a-f0-9]{16}$)/i
const SENSOR_REGEX = /(?:^\d{4,5}$|^[a-f0-9]{16}$)/i

type GatewayData = {
  id: string
  outletId: string
  isSaved: boolean
}
type SensorData = {
  id: string
  outletId: string
  modelName: string
  sensorId: string
  isSaved: boolean
  metadata: SensorMetadataResponse
}
type GatewayFormData = {
  data: {
    gateway: GatewayData
    sensors: SensorData[]
  }[]
}
type GatewayItemData = {
  gateway: GatewayData
  sensors: SensorData[]
  id: string
}

const GatewayAndSensors = ({
  gatewayData,
  sensorModels,
  site
}: {
  gatewayData: GatewayWithSensors[]
  sensorModels: string[]
  site: Site
}) => {
  const transformToFormData = (gws: GatewayWithSensors[]): GatewayFormData['data'] => {
    return gws.map(d => ({
      gateway: {
        id: d.gateway.id,
        outletId: d.gateway.outletId,
        isSaved: true
      },
      sensors: d.sensors.map(s => ({
        id: s.id,
        outletId: d.gateway.outletId,
        modelName: s.modelName,
        sensorId: s.sensorId,
        isSaved: true,
        metadata: s.metadata
      }))
    }))
  }

  const {actions, state, effects} = useAppState()
  const {t, i18n} = useTranslation('locations')

  // Load Gateways' latest values when rendinging this tab
  useEffect(() => {
    actions.v1.settings.gateways.getGatewayLatestValues({siteId: site.id})
  }, [])

  const {register, control, errors, clearError, reset, setValue, triggerValidation, handleSubmit, getValues} = useForm<
    GatewayFormData
  >({
    defaultValues: {
      data: transformToFormData(gatewayData)
    },
    mode: 'onChange'
  })
  const {fields, append, remove} = useFieldArray<GatewayFormData['data']>({
    control,
    name: 'data'
  })
  const [error, setError] = useState(false)
  const isValid = isEmpty(errors)

  const resetData = async () => {
    await actions.getGatewaysWithSensorsForSite(site.id)
    const newData = transformToFormData(gatewayData)
    reset({data: newData})
    setValue('data', newData)
    newData.forEach((newGateway, index) => setValue(`data[${index}].sensors`, newGateway.sensors))
    triggerValidation()
  }
  const onSubmit = async (values: GatewayFormData) => {
    setError(false)
    const newData = values.data ?? []
    try {
      await effects.adminApi.updateGatewaysAndSensorsForSite(site.id, newData as any)
      actions.reloadApp()
    } catch (err) {
      console.error(err)
      setError(true)
    }
  }
  const onCancel = async () => {
    setError(false)
    try {
      await resetData()
    } catch (err) {
      console.error(err)
      setError(true)
    }
  }
  const onAppend = () => {
    append({gateway: {id: '', outletId: '', name: '', isSaved: false}, sensors: []} as any)
  }
  const onRemove = async (index: number) => {
    const sensors = (fields?.[index] as {sensors: SensorData[]}).sensors ?? []
    remove(index)
    // For some reason errors need to be cleared from the last index rather than the removed index
    const lastIndex = fields.length - 1
    const sensorFieldNames = sensors.reduce((res: string[], _, idx) => {
      return [
        ...res,
        `data[${index}].sensors[${idx}].id`,
        `data[${index}].sensors[${idx}].sensorId`,
        `data[${index}].sensors[${idx}].modelName`
      ]
    }, [])

    const clonedGws: GatewayWithSensors[] = gatewayData?.map(d => ({
      gateway: d.gateway,
      sensors: d.sensors.map(s => s)
    }))
    clonedGws.splice(index, 1)

    await effects.adminApi.updateGatewaysAndSensorsForSite(site.id, clonedGws)
    clearError([`data[${lastIndex}].gateway.id`, `data[${lastIndex}].gateway.outletId`, ...sensorFieldNames])
  }

  const onGatewayIdBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value
    const trimmed = value.trim()
    let transformed = trimmed
    if (NOKEVAL_GATEWAY_ID_REGEX.test(transformed)) {
      transformed = transformed.toUpperCase()
    } else if (LORAWAN_EUI_REGEX.test(transformed)) {
      transformed = transformed.toLowerCase()
    }
    event.target.value = transformed
  }

  const gatewayLatestValues = state.v1.settings.gateways.latestValues

  return (
    <form>
      <Column style={{margin: '1rem 0'}}>
        {fields.map((item, index: number) => {
          const gws = item as GatewayItemData
          const gatewayErrorMessage = errors?.data?.[index]?.gateway?.outletId?.message
          const gatewayLatest = gatewayLatestValues?.find(g => g.gatewayId === gws.gateway.outletId)
          const gatewayTimeAgo =
            (gatewayLatest?.receivedAt && getDatetimeAgo(new Date(gatewayLatest.receivedAt), i18n.language)) ||
            t('locations:labels.gateways.noData', 'No data found')

          return (
            <div key={gws.id}>
              <Grid key={gws.id}>
                <input type="hidden" ref={register} name={`data[${index}].gateway.id`} />
                <BigLabelInput
                  ref={register({
                    required: {value: true, message: t('common:validation.requiredField', 'Required field')},
                    // Gateway ID has format A123456 or 0123456789abcdef
                    pattern: {
                      value: GATEWAY_REGEX,
                      message: t('locations:validation.gatewayIdInvalid', 'Invalid gateway ID')
                    }
                  })}
                  name={`data[${index}].gateway.outletId`}
                  labelText={t('locations:labels.gateways.gatewayId', 'Gateway ID')}
                  placeholder={t('locations:placeholders.gateways.gatewayId', 'Gateway ID')}
                  errorMsg={gatewayErrorMessage}
                  onBlur={onGatewayIdBlur}
                />
                <BigLabelInput
                  value={gatewayTimeAgo}
                  disabled
                  labelText={t('locations:labels.gateways.latestData', 'Latest data arrived')}
                />
                <DeleteButton text={t('common:actions.delete', 'Delete')} onClick={() => onRemove(index)} />
              </Grid>
              <SensorFields
                nestIndex={index}
                {...{control, register, errors, clearError}}
                sensorModels={sensorModels}
                site={site}
                gatewayWithSensors={gatewayData}
                gatewayId={gws.gateway.id}
                formValues={getValues()}
              />
            </div>
          )
        })}
        <div style={{height: '1px', width: '100%', backgroundColor: colors.system.lightGrey_5}} />
        <AddButton text={t('locations:buttons.addNewGateway', 'Add a new gateway')} onClick={onAppend} />
      </Column>
      <ButtonRowWrap>
        <div className="empty" />
        <Column>
          {error && (
            <SubmitErrorMessage>{t('common:errors.undefinedError', 'Something went wrong')}</SubmitErrorMessage>
          )}
          <ButtonRowGrid>
            <Button name="cancel-button" negative variant="secondary" onClick={onCancel}>
              {t('common:buttons.cancel', 'Cancel')}
            </Button>
            <Button variant="secondary" disabled={!isValid} onClick={handleSubmit(onSubmit)}>
              {t('common:buttons.save', 'Save')}
            </Button>
          </ButtonRowGrid>
        </Column>
      </ButtonRowWrap>
    </form>
  )
}

const SensorFieldsContainer = styled.div`
  margin: 2em 0 2rem 1rem;
`

const SensorFieldsRow = styled.div`
  margin: 0rem 0rem;
  display: grid;
  grid-template-columns: 2fr 3fr 3fr 1fr;
  grid-auto-rows: minmax(100px, auto);
  gap: 2rem;
`

type SensorFieldsProps = {
  nestIndex: number
  sensorModels: string[]
  control: Control<GatewayFormData>
  // These React Hook Form functions are rather difficult to type properly, so hence `any`
  register: any
  errors: any
  clearError: any
  site: Site
  gatewayWithSensors: GatewayWithSensors[]
  gatewayId: string
  formValues: GatewayFormData
}

const SensorFields: React.FC<SensorFieldsProps> = ({
  nestIndex,
  control,
  register,
  sensorModels,
  errors,
  clearError,
  site,
  gatewayWithSensors,
  gatewayId
}) => {
  const {state, effects, actions} = useAppState()
  const {t, i18n} = useTranslation('locations')
  const {fields, append, remove} = useFieldArray<SensorData>({
    control,
    name: `data[${nestIndex}].sensors`
  })
  const [sensor, setSensor] = useState<number | undefined>(undefined)

  const handleSensorDelete = async (index: number) => {
    remove(index)

    const clonedGws: GatewayWithSensors[] = gatewayWithSensors?.map(d => ({
      gateway: d.gateway,
      sensors: d.sensors.map(s => s)
    }))
    if (clonedGws) {
      for (let gateway of clonedGws) {
        if (gateway.gateway.id === gatewayId) {
          gateway.sensors.splice(index, 1)
        }
      }
      await effects.adminApi.updateGatewaysAndSensorsForSite(site.id, clonedGws)
    }

    // For some reason errors need to be cleared from the last index rather than the removed index
    const lastIndex = fields.length - 1
    clearError([
      `data[${nestIndex}].sensors[${lastIndex}].id`,
      `data[${nestIndex}].sensors[${lastIndex}].sensorId`,
      `data[${nestIndex}].sensors[${lastIndex}].modelName`
    ])
    setSensor(undefined)
  }

  const onSensorIdBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value
    const trimmed = value.trim()
    let transformed = trimmed
    if (LORAWAN_EUI_REGEX.test(transformed)) {
      transformed = transformed.toLowerCase()
    }
    event.target.value = transformed
  }

  const gatewaySignalStrengths = state.v1.settings.gateways.signalStrengths

  return (
    <SensorFieldsContainer style={{margin: '2em 0 2rem 1rem'}}>
      {sensor !== undefined && (
        <DeleteSensorModal
          handleApprove={handleSensorDelete}
          sensor={sensor}
          isOpen={sensor !== undefined}
          onClose={() => setSensor(undefined)}
        />
      )}
      {fields.map((item, index: number) => {
        const sensor = item
        const sensorModelName = sensor.modelName || ''
        const sensorError = errors?.data?.[nestIndex]?.sensors?.[index]
        const newSignalStrength = gatewaySignalStrengths
          ?.find(g => g.gatewayId === item.outletId)
          ?.sensors?.find(s => s.sensorId === item.sensorId)
        return (
          <SensorFieldsRow key={sensor.id}>
            <input type="hidden" ref={register} name={`data[${nestIndex}].sensors[${index}].id`} />
            <LabelInput
              style={{marginRight: '1rem', width: '100%'}}
              // Sensor ID has format 1234 or 12345 or 0123456789abcdef
              ref={register({
                required: {value: true, message: t('common:validation.requiredField', 'Required field')},
                pattern: {value: SENSOR_REGEX, message: t('locations:validation.sensorIdInvalid', 'Invalid sensor ID')}
              })}
              name={`data[${nestIndex}].sensors[${index}].sensorId`}
              placeholder={t('locations:placeholders.gateways.sensorId', 'Enter an ID')}
              labelText={t('locations:gateways.sensorId', 'Sensor ID')}
              errorMsg={sensorError?.sensorId?.message}
              onBlur={onSensorIdBlur}
            />
            <Select
              returnId={true}
              nativeProps={{
                defaultValue: sensorModelName
              }}
              ref={register({
                required: {value: true, message: t('common:validation.requiredField', 'Required field')}
              })}
              id={`data[${nestIndex}].sensors[${index}].modelName`}
              label={t('locations:gateways.sensorModel', 'Sensor Model')}
              emptyStr={t('locations:labels.gateways.sensorModel', 'Select a model')}
              options={[...sensorModels.map(s => ({option: s, id: s}))]}
              errorMsg={sensorError?.modelName?.message}
            />
            {newSignalStrength && (
              <LabelInput
                style={{
                  backgroundColor: colors.system.white,
                  borderColor: colors.system.white,
                  marginRight: '1rem',
                  width: '100%'
                }}
                labelText={t('appliances:labels.signalStrength', 'Signal strength')}
                value={`${newSignalStrength.signalStrength ?? '---'} dBm (${getDatetimeAgo(
                  parseISO(newSignalStrength.receivedAt),
                  i18n.language
                )})`}
                disabled
              />
            )}
            <DeleteButton text={t('common:actions.delete', 'Delete')} onClick={() => setSensor(index)} />
          </SensorFieldsRow>
        )
      })}
      <AddButton
        text={t('locations:buttons.addNewSensor', 'Add a new sensor')}
        onClick={() => append({sensorId: '', modelName: '', isSaved: false})}
      />
    </SensorFieldsContainer>
  )
}

const AddButton = (props: {text: string; onClick: () => void}) => (
  <div style={{display: 'flex', alignItems: 'center', margin: '1rem 0', cursor: 'pointer'}} onClick={props.onClick}>
    <IconAddItem width="1.5rem" height="1.5rem" color="black" />
    <span style={{fontSize: '1rem', marginLeft: '0.8rem'}}>{props.text}</span>
  </div>
)

const DeleteButton = (props: {text: string; onClick: () => void}) => (
  <div style={{display: 'flex', alignItems: 'center', margin: '1rem 0', cursor: 'pointer'}} onClick={props.onClick}>
    <IconDelete />
    <span style={{fontSize: '1rem', marginLeft: '0.8rem'}}>{props.text}</span>
  </div>
)

export default ({id}: {id?: string}) => {
  const {state} = useAppState()
  const site = normalizeSites(state.chainsById[state.selectedChainId!]).find(s => s.id === id)
  return <EditSite saved={site} />
}
