import React from 'react'
import {useNavigation} from 'react-navi'
import {useTranslation} from 'react-i18next'

import {MainLayoutWithoutStretch, MainContent} from '../../Layout/Layout'
import {Baseline} from '../../Layout/Grid'
import {GoBackHeaderTitle, HeaderRow} from '../../Molecules/ViewComponents/ViewHeader'
import {useAppState} from '../../../state'
import {A11yListContainer, A11yIconListItem} from '../../Atoms/Lists'
import {H3} from '../../Atoms/Typography'
import {Helmet} from 'react-navi-helmet-async'
import {SharedIcon} from '@fredman/chefstein-shared-frontend-components'
import {taskGroupIconPicker} from '../../../pages/tasks/utils'

const EquipmentTypeSelect = () => {
  const nav = useNavigation()
  const {state} = useAppState()
  const {equipmentTypes} = state
  const {t} = useTranslation('appliances')
  return (
    <MainLayoutWithoutStretch>
      <Helmet title={t('common:routes.selectApplianceToAdd', 'Select appliance type')} />
      <MainContent variant="white">
        <HeaderRow>
          <GoBackHeaderTitle
            label={t('appliances:labels.selectApplianceToAdd', 'Select appliance type')}
            path={'/appliance'}
            backLabel={t('appliances:labels.applianceSettings', 'Appliance settings')}
          />
        </HeaderRow>
        <Baseline>
          <A11yListContainer>
            {equipmentTypes && equipmentTypes.length > 0
              ? equipmentTypes.map(et => (
                  <A11yIconListItem
                    key={et.id}
                    onClick={() => nav.navigate(`/create/appliance/${et.id}/`)}
                    icon={<SharedIcon icon={taskGroupIconPicker(et.icon)} />}
                    content={<H3>{t(`appliances:equipmentTypes.${et.id}`, et.name)}</H3>}
                  />
                ))
              : null}
          </A11yListContainer>
        </Baseline>
      </MainContent>
    </MainLayoutWithoutStretch>
  )
}

export default EquipmentTypeSelect
