import React, {useEffect, useState} from 'react'
import styled, {css} from 'styled-components'
import {Helmet} from 'react-navi-helmet-async'
import {ErrorMessage} from '../../Components/Molecules/Errors/ErrorMessage'
import {Loader as PageLoader} from '../../Routes'
import {useAppState} from '../../state'
import {EquipmentTypeKey} from '../../state/rest'
import {Appliance} from '../../state/appliances/state'
import {
  Title,
  Text,
  CardGrid,
  ApplianceCard,
  Select,
  SelectOption,
  ApplianceDataCycleProgressType,
  SharedIcon,
  IconPalette
} from '@fredman/chefstein-shared-frontend-components'
import {colors} from '../../sharedComponents/colors'

import {uniqBy, orderBy} from 'lodash'
import {useNavigation} from 'react-navi'
import {ApplianceDetailsCard} from './ApplianceDetailsCard'
import {
  getApplianceData,
  getApplianceIcon,
  getIsCoolerAppliance,
  hasApplianceCoolingLowAlarm,
  isApplianceCoolingInProgress
} from './appliancesUtils'
import {ApplianceStatusModal} from './ApplianceStatusModal'
import {OrgLevelType} from '../../state/state'
import {useTranslation} from 'react-i18next'
import {TFunction} from 'i18next'
import {ApplianceCoolingProgress} from './ApplianceCoolingProgress'
import {CenteredLayout} from '../common/centeredLayout'

const APPLIANCE_TYPE_FILTER_OPTION_ALL = 'all'
const APPLIANCES_DATA_POLLINTERVAL_MS = 15000

interface AppliancesPageProps {
  applianceId?: string
}

export const AppliancesPage: React.FC<AppliancesPageProps> = props => {
  const {state, actions} = useAppState()
  const {t} = useTranslation('appliances')
  const navigation = useNavigation()
  const [showDetails, setShowDetails] = useState(Boolean(props.applianceId))
  const [filters, setFilters] = useState<SelectOption[]>([
    getApplianceTypeFilterOption(APPLIANCE_TYPE_FILTER_OPTION_ALL, t)
  ])
  const [selectedFilter, setSelectedFilter] = useState<SelectOption>(filters[0])
  const [expanded, setExpanded] = useState(false)
  const [selectedSensorId, setSelectedSensorId] = useState<string>()
  const [isApplianceStatusModalOpen, setIsApplianceStatusModalOpen] = useState(false)
  const [currentSiteId, setCurrentSiteId] = useState(state.site?.id)

  const siteId = state.site?.id
  const appliances = state.v1.appliances.appliances.data.appliances
  const appliancesError = state.v1.appliances.appliances.error
  const selectedAppliance = appliances?.find(appliance => appliance.id === props.applianceId)

  useEffect(() => {
    // Redirect to home page if site is not selected because atm there is no appliance page for chain
    if (state.orgLevel?.type !== OrgLevelType.SITE) {
      navigation.navigate('/')
    }
  }, [state.orgLevel])

  useEffect(() => {
    const fetchData = async () => {
      if (!state.site?.id) {
        return
      }
      await actions.v1.appliances.getAppliancesForSite({siteId: state.site.id})
      const fetchedAppliances = state.v1.appliances.appliances.data.appliances
      if (!fetchedAppliances) {
        return
      }
      const applianceTypeFilters = getApplianceTypeFilterOptions(fetchedAppliances, t)
      setFilters(applianceTypeFilters)
      // Reset appliance type filter if selected filter does not exist
      if (!applianceTypeFilters.map(filter => filter.value).includes(selectedFilter.value)) {
        setSelectedFilter(applianceTypeFilters[0])
      }
    }
    // Site has changed
    if (state.site?.id && currentSiteId !== state.site?.id) {
      actions.v1.appliances.resetAppliances()
      setCurrentSiteId(state.site.id)
      // Close appliance details card by changing URL path
      navigation.navigate('/appliances')
    }
    fetchData()
    const intervalId = window.setInterval(() => {
      fetchData()
    }, APPLIANCES_DATA_POLLINTERVAL_MS)
    return () => {
      window.clearInterval(intervalId)
    }
  }, [actions.v1.appliances, siteId])

  useEffect(() => {
    if (window.location.pathname === '/appliances') {
      setShowDetails(false)
    }
  })

  const onApplianceCardClick = (applianceId: string, sensorId?: string) => {
    if (applianceId !== props.applianceId) {
      navigation.navigate(`/appliances/${applianceId}`)
      setShowDetails(true)
    }
    setSelectedSensorId(sensorId || appliances?.find(appliance => appliance.id === applianceId)?.sensors?.[0]?.id)
  }

  const onBackgroundClick = () => {
    // Close appliance details card when clicking on background
    // If appliance details card shouldn't be closed by clicking on specific element
    // that element should have event.stopPropagation() in its click event handler
    navigation.navigate('/appliances')
  }

  const onSwitchChange = () => {
    if (!selectedAppliance) {
      return
    }
    if (selectedAppliance.suspended === false) {
      onToggleApplianceStatusModal()
    } else {
      actions.v1.appliances.putStatusForAppliance({applianceId: selectedAppliance.id, suspended: false})
    }
  }

  const onToggleApplianceStatusModal = () => {
    setIsApplianceStatusModalOpen(!isApplianceStatusModalOpen)
  }

  const onExpandButtonClick = () => {
    setExpanded(!expanded)
  }

  if (!appliances) {
    return <PageLoader show={!appliances} />
  } else if (!appliances && appliancesError) {
    return <ErrorMessage message={appliancesError.message} />
  } else {
    const filteredAppliances =
      selectedFilter.value === APPLIANCE_TYPE_FILTER_OPTION_ALL
        ? appliances
        : appliances.filter(appliance => appliance.type === selectedFilter.value)
    const appliancesWithoutSelected = filteredAppliances.filter(appliance => appliance.id !== selectedAppliance?.id)

    return (
      <CenteredLayout onClick={onBackgroundClick}>
        <Helmet title={t('common:routes.appliances', 'Appliances')} />
        <ApplianceStatusModal
          isOpen={isApplianceStatusModalOpen}
          onClose={onToggleApplianceStatusModal}
          applianceId={selectedAppliance?.id}
        />
        <TitleContainer>
          <Title
            level={3}
            onClick={event => {
              event.stopPropagation()
            }}
          >
            {t('appliances:labels.headingApplianceList', 'Appliances')}
          </Title>
          <Filter
            onClick={event => {
              event.stopPropagation()
            }}
          >
            <Text size="S">
              {t('appliances:labels.showingItems', 'Showing {{items}} items', {
                items: filteredAppliances.length
              })}
            </Text>
            <FilterSelectContainer>
              <Select options={filters} value={[selectedFilter]} onChange={setSelectedFilter} />
            </FilterSelectContainer>
          </Filter>
        </TitleContainer>
        <Content>
          {appliances.length === 0 ? (
            <NoAppliancePlaceHolder />
          ) : (
            <>
              <ApplianceDetailsCard
                appliance={selectedAppliance}
                metadataExpanded={expanded}
                selectedSensorId={selectedSensorId}
                onExpandButtonClick={onExpandButtonClick}
                showDetails={showDetails}
                onSwitchChange={onSwitchChange}
                onClick={event => {
                  event.stopPropagation()
                }}
              />
              <CardGridContainer showDetails={showDetails}>
                <CardGrid compact>
                  {appliancesWithoutSelected.map(appliance => {
                    let data = undefined
                    let tagLabel = undefined
                    if (isApplianceCoolingInProgress(appliance)) {
                      data = [
                        <ApplianceCoolingProgress
                          appliance={appliance}
                          cycleProgressType={ApplianceDataCycleProgressType.small}
                          onClick={event => {
                            event.stopPropagation()
                            onApplianceCardClick(appliance.id, appliance.sensors?.[0]?.id)
                          }}
                          hasAlarm={hasApplianceCoolingLowAlarm(appliance)}
                        />
                      ]
                      tagLabel = t('appliances:labels.cooling', 'Cooling').toUpperCase()
                    } else {
                      data = getApplianceData(t, appliance, state.site!)
                      data.forEach(d => {
                        d.onClick = event => {
                          event.stopPropagation()
                          onApplianceCardClick(appliance.id, d.sensorUuid)
                        }
                        d.maxValue = getIsCoolerAppliance(appliance) ? d.targetValue : d.maxValue
                      })
                    }
                    return (
                      <StyledApplianceCard
                        key={appliance.id}
                        icon={getApplianceIcon(appliance.type)}
                        data={data}
                        name={appliance.name}
                        disabled={appliance.suspended}
                        activeAlarmCount={appliance.unresolvedAlarmCount ?? 0}
                        onClick={event => {
                          event.stopPropagation()
                          onApplianceCardClick(appliance.id)
                        }}
                        tagLabel={tagLabel}
                        compact
                      />
                    )
                  })}
                </CardGrid>
              </CardGridContainer>
            </>
          )}
        </Content>
      </CenteredLayout>
    )
  }
}

export const NoAppliancePlaceHolder: React.FC = props => {
  const {t} = useTranslation('appliances')

  return (
    <NoApplianceContainer>
      <div>
        {[...Array(3)].map((e, i) => (
          <SharedIcon key={i} icon={IconPalette.ListSingle} height="108px" width="80px" fill="#BFBFBF" />
        ))}
      </div>
      <Text size="M" strong>
        {t('appliances:labels.noAppliance.title', 'There are no appliances at this site.')}
      </Text>
      <Text size="M">
        {t('appliances:labels.noAppliance.description', 'You can contact your manager for more information.')}
      </Text>
    </NoApplianceContainer>
  )
}

const getApplianceTypeFilterOptions = (appliances: Appliance[], t: TFunction) => {
  const filterOptions = [
    getApplianceTypeFilterOption(APPLIANCE_TYPE_FILTER_OPTION_ALL, t),
    ...appliances.map(appliance => getApplianceTypeFilterOption(appliance.type, t))
  ]
  const sortedFilterOptions = sortApplianceTypeFilterOptions(uniqBy(filterOptions, 'value'))
  return sortedFilterOptions
}

const getApplianceTypeFilterOption = (type: string, t: TFunction): SelectOption => {
  switch (type) {
    case EquipmentTypeKey.STORAGE:
      return {value: EquipmentTypeKey.STORAGE, label: t('appliances:labels.storages', 'Storages')}
    case EquipmentTypeKey.FREEZER:
      return {value: EquipmentTypeKey.FREEZER, label: t('appliances:labels.freezers', 'Freezers')}
    case EquipmentTypeKey.WASTE:
      return {value: EquipmentTypeKey.WASTE, label: t('appliances:labels.wasteScales', 'Waste scales')}
    case EquipmentTypeKey.DISHWASHER:
      return {value: EquipmentTypeKey.DISHWASHER, label: t('appliances:labels.dishwashers', 'Dishwashers')}
    case EquipmentTypeKey.COOLER:
      return {value: EquipmentTypeKey.COOLER, label: t('appliances:labels.coolers', 'Coolers')}
    case EquipmentTypeKey.OVEN:
      return {value: EquipmentTypeKey.OVEN, label: t('appliances:labels.ovens', 'Ovens')}
    default:
      return {value: APPLIANCE_TYPE_FILTER_OPTION_ALL, label: t('appliances:labels.allAppliances', 'All appliances')}
  }
}

const sortApplianceTypeFilterOptions = (options: SelectOption[]) => {
  return orderBy(
    options,
    option => {
      switch (option.value) {
        case APPLIANCE_TYPE_FILTER_OPTION_ALL:
          return 6
        case 'waste-scale':
          return 5
        case 'storage':
          return 4
        case 'cooler':
          return 3
        case 'dishwasher':
          return 2
        case 'freezer':
          return 1
        default:
          return 0
      }
    },
    'desc'
  )
}

const TitleContainer = styled.div`
  padding-bottom: 0.75rem;
  margin-bottom: 1.25rem;
  border-bottom: 1px solid ${colors.system.grey_5};
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Filter = styled.div`
  display: inline-grid;
  gap: 1rem;
  grid-auto-flow: column;
  align-items: center;
`

const FilterSelectContainer = styled.div`
  width: 13.75rem;
`

const Content = styled.div`
  display: flex;
  align-items: flex-start;
`

const CardGridContainer = styled.div<{showDetails?: boolean}>(
  ({showDetails}) => css`
    padding-bottom: 1.25rem;
    flex-basis: ${showDetails ? '50%' : '100%'};
    transition: all 0.3s ease-out;
    ${showDetails && 'margin-left: 1rem;'}
    max-height: calc(100vh - 10.375rem);
    overflow-y: auto;
    overflow-x: hidden;
  `
)

const StyledApplianceCard = styled(ApplianceCard)`
  cursor: pointer;
`

const NoApplianceContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  margin-top: 10%;
`
